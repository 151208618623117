import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { convertCountry } from '../../utils/convert-currency';
import { PaymentStatus } from './payment-status/payment-status';
import { useOrderQuery } from '../../graphql';
import { Button, Skeleton } from '../flexyui';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../components/flexyui/Accordion';
import { OrderEdit } from '../orders/order-edit';
import { FulfilledStatus } from './fulfillment-status/fulfillment-status';

type Props = {
  order: any;
  handleShowOrderDetail: () => void;
  updateOrderEditRow: (orderName: string, orderDetails: any) => void;
};

export const OrderDetails: React.FC<Props> = ({ order, handleShowOrderDetail, updateOrderEditRow }) => {
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  const {
    data: orderDetails,
    loading,
    refetch
  } = useOrderQuery({
    variables: {
      orderId: order?.id
    }
  });

  useEffect(() => {
    if (orderDetails) updateOrderEditRow(order?.name, orderDetails?.order);
  }, [orderDetails]);

  return (
    <div className="w-full sm:w-[570px] overflow-y-scroll scrollbar-hide">
      {/* order id */}
      <div className="sticky top-0 bg-white z-50">
        <div className="flex items-center justify-between font-semibold px-3 py-3.5 sm:p-4">
          <div className="flex text-sm gap-2 items-center">
            <Link
              to={orderDetails?.order?.platform_order_admin_url || ''}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
              className="text-lg text-[#2F72FF] font-semibold hover:underline mr-2"
            >
              {order?.name}
            </Link>
            {loading ? (
              <Skeleton size={'label'} />
            ) : (
              <PaymentStatus text={orderDetails?.order?.order_invoice?.payment_state} />
            )}
            {loading ? (
              <Skeleton size={'label'} />
            ) : (
              <FulfilledStatus text={orderDetails?.order?.platform_fulfillment_status || null} />
            )}
          </div>
          <div className="flex items-center gap-2">
            <div className="hidden sm:block">
              <Button
                size="sm"
                variant="outline"
                className="h-8"
                onClick={() => setOpenEditDialog(true)}
                disabled={orderDetails?.order?.platform_fulfillment_status === 'fulfilled'}
              >
                Edit
              </Button>
              <OrderEdit order={order} refetch={refetch} open={openEditDialog} setOpen={setOpenEditDialog} />
            </div>
            <Button type="submit" size="icon" variant="icon" onClick={handleShowOrderDetail}>
              <Unicons.UilTimes className="text-[#2F72FF]" />
            </Button>
          </div>
        </div>
        <hr />
      </div>

      <div className="px-3 mt-4 flex pb-4 items-center gap-2 sm:px-4">
        <div className="font-semibold">Date:</div>
        <div className="text-[#595F74] ">{dayjs(orderDetails?.order?.created_at).format('MMM D YYYY, hh:mm A')}</div>
      </div>

      <div className="px-3 sx:px-4">
        <Accordion type="single" defaultValue="item-1" collapsible className="!mt-0">
          {/* Order Summary */}
          <AccordionItem value="item-1" className="mb-4">
            <AccordionTrigger className="bg-[#F3F4F5] text-[#121B38] rounded-lg px-4 py-3">
              <div className="text-sm font-semibold">Order Summary</div>
            </AccordionTrigger>
            <AccordionContent className="px-4 pt-4 !pb-0">
              {loading &&
                [0, 1, 2].map((index) => (
                  <div key={index} className="flex justify-between mb-4">
                    <div className="flex gap-2">
                      <div className="w-[50px] flex justify-center">
                        <Skeleton size={'xl'} className="h-12" />
                      </div>
                      <div>
                        <Skeleton size={'label'} className="w-40" />
                        <Skeleton size={'md'} className="mt-1.5" />
                      </div>
                    </div>
                    <Skeleton size={'label'} className="w-20" />
                  </div>
                ))}
              {orderDetails?.order?.ordered_products?.map((data, index) => {
                return (
                  <div key={index} className="flex justify-between mb-4">
                    <div className="flex gap-2">
                      <div className="w-[48px] flex justify-center">
                        {data?.product_image_url ? (
                          <img src={data?.product_image_url} className="h-[48px] rounded-md" />
                        ) : (
                          <div className="border rounded-lg border-black-200 border-solid p-2">
                            <Unicons.UilImage style={{ height: '1.5rem', width: '1.5rem' }} />
                          </div>
                        )}
                      </div>
                      <div className="flex-col">
                        <div className="font-semibold text-[#121B38]">{data?.variant_title}</div>
                        <div className="text-[#595F74]">
                          {orderDetails?.order?.order_invoice?.currency && (
                            <>
                              {convertCountry(orderDetails?.order?.order_invoice?.currency)} {data?.price} x{' '}
                              {data?.quantity}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      {orderDetails?.order?.order_invoice?.currency && (
                        <>
                          {convertCountry(orderDetails?.order?.order_invoice?.currency)}{' '}
                          {Number(data?.price) * Number(data?.quantity)}
                        </>
                      )}{' '}
                    </div>
                  </div>
                );
              })}
              <div className="flex justify-between  mb-2">
                <div>Subtotal</div>
                <div>
                  {loading ? (
                    <Skeleton size={'label'} className="w-14 h-4 !mr-0" />
                  ) : (
                    orderDetails?.order?.order_invoice?.currency && (
                      <>
                        {convertCountry(orderDetails?.order?.order_invoice?.currency)}{' '}
                        {orderDetails?.order?.order_invoice?.subtotal}{' '}
                      </>
                    )
                  )}
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>Discount</div>
                <div>
                  {loading ? (
                    <Skeleton size={'label'} className="w-14 h-4 !mr-0" />
                  ) : (
                    orderDetails?.order?.order_invoice?.currency && (
                      <>
                        {convertCountry(orderDetails?.order?.order_invoice?.currency)}{' '}
                        {orderDetails?.order?.order_invoice?.discount}
                      </>
                    )
                  )}
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>Shipping charges</div>
                <div>
                  {loading ? (
                    <Skeleton size={'label'} className="w-14 h-4 !mr-0" />
                  ) : (
                    orderDetails?.order?.order_invoice?.currency && (
                      <>
                        {convertCountry(orderDetails?.order?.order_invoice?.currency)}{' '}
                        {orderDetails?.order?.order_invoice?.total_shipping_price ?? 0}
                      </>
                    )
                  )}
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <div>Tax @ 18% (included)</div>
                {loading ? (
                  <Skeleton size={'label'} className="w-14 h-4 !mr-0" />
                ) : (
                  orderDetails?.order?.order_invoice?.currency && (
                    <>
                      {convertCountry(orderDetails?.order?.order_invoice?.currency)}{' '}
                      {orderDetails?.order?.order_invoice?.total_tax ?? 0}
                    </>
                  )
                )}
              </div>
              <div className="flex justify-between mb-2 font-semibold">
                <div>Total</div>
                <div>
                  {loading ? (
                    <Skeleton size={'label'} className="w-14 h-4 !mr-0" />
                  ) : (
                    orderDetails?.order?.order_invoice?.currency && (
                      <>
                        {convertCountry(orderDetails?.order?.order_invoice?.currency)}{' '}
                        {orderDetails?.order?.order_invoice?.total_price}
                      </>
                    )
                  )}
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        {/* Customer Details */}
        <Accordion type="single" defaultValue="item-2" collapsible className="!mt-0">
          <AccordionItem value="item-2" className="mb-4">
            <AccordionTrigger className="bg-[#F3F4F5] text-[#121B38] rounded-lg px-4 py-3">
              <div className="text-sm font-semibold">Customer Details</div>
            </AccordionTrigger>
            <AccordionContent className="px-4 pt-4 !pb-0">
              <div className="grid grid-cols-2">
                <div className="flex-row  mb-2">
                  <div className="text-[#595F74] text-xs">Name</div>
                  <div className="text-[#121B38]">
                    {loading ? (
                      <Skeleton size={'label'} className="w-40 h-4 !ml-0" />
                    ) : (
                      <div className="text-[#121B38]">
                        {orderDetails?.order?.customer?.first_name} {orderDetails?.order?.customer?.last_name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-row  mb-2">
                  <div className="text-[#595F74] text-xs">Email</div>
                  <div className="text-[#121B38]">
                    {loading ? (
                      <Skeleton size={'label'} className="w-48 h-4 !ml-0" />
                    ) : (
                      <div className="text-[#121B38]">{orderDetails?.order?.customer?.email}</div>
                    )}
                  </div>
                </div>

                <div className="flex-row  mb-2">
                  <div className="text-[#595F74] text-xs">Phone</div>
                  <div className="text-[#121B38]">
                    {loading ? (
                      <Skeleton size={'label'} className="w-32 h-4 !ml-0" />
                    ) : (
                      <div className="text-[#121B38]">
                        {orderDetails?.order?.customer?.phone ? orderDetails?.order?.customer?.phone : 'N/A'}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        {/* Shipping Address */}
        <Accordion type="single" defaultValue="item-3" collapsible className="!mt-0">
          <AccordionItem value="item-3" className="mb-4">
            <AccordionTrigger className="bg-[#F3F4F5] text-[#121B38] rounded-lg px-4 py-3">
              <div className="text-sm font-semibold">Shipping Address</div>
            </AccordionTrigger>
            <AccordionContent className="px-4 pt-4 !pb-0" hidden={loading}>
              <div className="grid grid-cols-2">
                <div className="flex-row  mb-2">
                  <div className="text-[#595F74] text-xs">Name</div>
                  <div className="text-[#121B38]">
                    {orderDetails?.order?.shipping_address?.first_name}{' '}
                    {orderDetails?.order?.shipping_address?.last_name}
                  </div>
                </div>
                <div className="flex-row  mb-2">
                  <div className="text-[#595F74] text-xs">Phone</div>
                  <div className="text-[#121B38]"> {orderDetails?.order?.shipping_address?.phone}</div>
                </div>
                <div className="flex-row  mb-2">
                  <div className="text-[#595F74] text-xs">Address</div>
                  <div className="text-[#121B38] flex flex-col">
                    <div>{orderDetails?.order?.shipping_address?.address1}</div>
                    <div>
                      {orderDetails?.order?.shipping_address?.address2
                        ? `,${orderDetails?.order?.shipping_address?.address2}`
                        : ''}
                    </div>
                    <div className="flex">
                      {orderDetails?.order?.shipping_address?.city},&nbsp;
                      {orderDetails?.order?.shipping_address?.state} -&nbsp;
                      {orderDetails?.order?.shipping_address?.postal_code}
                    </div>
                    <div>{orderDetails?.order?.shipping_address?.country}</div>
                  </div>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        {/* Billing Address */}
        <Accordion type="single" defaultValue="item-4" collapsible className="!mt-0">
          <AccordionItem value="item-4" className="mb-2">
            <AccordionTrigger className="bg-[#F3F4F5] text-[#121B38] rounded-lg px-4 py-3">
              <div className="text-sm font-semibold">Billing Address</div>
            </AccordionTrigger>
            <AccordionContent className="px-4 pt-4 !pb-0" hidden={loading}>
              <div className="grid grid-cols-2">
                <div className="flex-row  mb-2">
                  <div className="text-[#595F74] text-xs">Name</div>
                  <div className="text-[#121B38]">
                    {orderDetails?.order?.billing_address?.first_name} {orderDetails?.order?.billing_address?.last_name}
                  </div>
                </div>
                <div className="flex-row  mb-2">
                  <div className="text-[#595F74] text-xs">Phone</div>
                  <div className="text-[#121B38]"> {orderDetails?.order?.billing_address?.phone}</div>
                </div>
                <div className="flex-row  mb-2">
                  <div className="text-[#595F74] text-xs flex flex-col">Address</div>
                  <div className="text-[#121B38]">
                    <div>{orderDetails?.order?.billing_address?.address1}</div>
                    <div>
                      {orderDetails?.order?.billing_address?.address2
                        ? `,${orderDetails?.order?.billing_address?.address2}`
                        : ''}
                    </div>
                    <div className="flex">
                      {orderDetails?.order?.billing_address?.city},&nbsp;
                      {orderDetails?.order?.billing_address?.state} -&nbsp;
                      {orderDetails?.order?.billing_address?.postal_code}
                    </div>
                    <div>{orderDetails?.order?.billing_address?.country}</div>
                  </div>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};
