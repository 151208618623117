import React from 'react';
import * as Unicons from '@iconscout/react-unicons';

type Props = {
  status: string;
};

export const CodStatus: React.FC<Props> = ({ status }) => {
  switch (status) {
    case 'INACTIVE':
      return (
        <div className="flex justify-start gap-0.5 items-center text-[#736000] bg-[#FFEDBD] rounded-2xl py-1 pl-2 pr-4 font-medium">
          <Unicons.UilCircle className="h-4" /> Inactive
        </div>
      );
    case 'PARTIALLY_ACTIVE':
      return (
        <div className="flex justify-start gap-0.5 items-center text-[#4f4700] bg-[#ffd6a4] rounded-2xl py-1 pl-2 pr-4 font-medium">
          <Unicons.UilAdjustHalf className="h-4" /> Partially active
        </div>
      );
    case 'ACTIVE':
      return (
        <div className="flex gap-0.5 items-center text-[#007305] bg-[#C7FFE0] rounded-2xl py-1 pl-2 pr-4 font-medium">
          <Unicons.UilCheck className="h-4" /> Active
        </div>
      );

    default:
      return <>-</>;
  }
};
