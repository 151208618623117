import { ShippingType } from '../graphql';

export const ShippingTableHeaders = [
  {
    title: 'Group name'
  },
  {
    title: 'Default ETD Text'
  },

  {
    title: 'Active Status'
  },
  {
    title: ''
  }
];

export const pricingRange = (type: any) => {
  return [
    {
      title: type === ShippingType.Price ? 'Min Order Price' : 'Min Order Weight'
    },
    {
      title: type === ShippingType.Price ? 'Max Order Price' : 'Max Order Weight'
    },
    {
      title: 'Shipping Charges'
    }
  ];
};
