/* eslint-disable camelcase */
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Checkbox, Chip, Label } from '../../../../flexyui';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/confirmation-dialog';
import { Pixel, useDeleteMetaAnalyticsMutation, useUpdateMetaAnalyticsMutation } from '../../../../../graphql';

type Props = {
  pixel: Pixel;
  handleEditClick: (value: Pixel) => void;
  handleMetaPixelStatus: () => void;
  refetch: () => void;
};

export const MetaPixelCard: React.FC<Props> = ({ pixel, handleEditClick, handleMetaPixelStatus, refetch }) => {
  const [updateMeta] = useUpdateMetaAnalyticsMutation();
  const [deleteMeta, { loading: deleteLoading }] = useDeleteMetaAnalyticsMutation();

  const [pixelStatus, setPixelStatus] = useState<boolean>(pixel.enabled);
  const [pixelToDelete, setPixelToDelete] = useState<Pixel | null>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  useEffect(() => {
    if (pixel) setPixelStatus(pixel.enabled);
  }, [pixel]);

  const handlePixelStatusUpdate = async (value: boolean) => {
    setPixelStatus(value);

    const { id, ...rest } = pixel;

    try {
      await updateMeta({
        variables: {
          configs: {
            ...rest,
            enabled: value
          },
          updateMetaAnalyticsId: pixel.id
        }
      });
      enqueueSnackbar(`Pixel has been ${value ? 'enabled' : 'disabled'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });

      setPixelStatus(pixel.enabled);
    }
  };

  const handleDeletePixel = async () => {
    if (!pixelToDelete) return;

    try {
      handleMetaPixelStatus();
      await deleteMeta({
        variables: {
          deleteMetaAnalyticsId: pixelToDelete.id
        }
      });

      enqueueSnackbar('Pixel has been deleted successfully!', {
        variant: 'success'
      });

      refetch();
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setPixelToDelete(null);
      setShowConfirmationDialog(false);
    }
  };

  return (
    <>
      <div className="text-[#888D9B] text-xs border border-muted px-4 py-4 rounded-[6px] mb-3 last:mb-0">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Checkbox
              checked={pixelStatus}
              id={'pixel_enabled'}
              name={'pixelEnabled'}
              onChange={(e) => handlePixelStatusUpdate(e.target.checked)}
            />
            <div className="grid gap-[5px] leading-none ml-3">
              <Label htmlFor={'pixel_enabled'} size={'paragraph'} className="font-normal text-[#595F74]">
                Enable this pixel
              </Label>
            </div>
          </div>
          <div className="flex items-center">
            <Button size="icon" variant="icon" onClick={() => handleEditClick(pixel)} className="mr-2">
              <Unicons.UilEdit size={'22'} className="p-[2px] text-[#595F74]" />
            </Button>
            <Button
              size="icon"
              variant="icon"
              onClick={() => {
                setPixelToDelete(pixel);
                setShowConfirmationDialog(true);
              }}
              className="text-[#595F74] hover:text-red-500"
            >
              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
            </Button>
          </div>
        </div>
        <div className="mt-3 font-normal text-[#595F74]">Pixel ID</div>
        <div className="flex justify-start text-sm text-[#121B38] font-normal">
          {pixel?.pixel_id ? pixel?.pixel_id : '-'}
        </div>
        <div className="mt-3 flex items-center">
          <div className="font-normal text-[#595F74] mr-2">Conversion API Token</div>
          <Chip
            text={pixel.conversion_api_enabled ? 'Enabled' : 'Disabled'}
            variant={pixel.conversion_api_enabled ? 'success' : 'draft'}
          />
        </div>
        <div className="max-w-[80%] mt-0.5 text-sm text-[#121B38] font-normal break-all">
          {pixel?.conversion_api_token ? pixel?.conversion_api_token : '-'}
        </div>
      </div>

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleDeletePixel}
        headerText="Delete Pixel?"
        text="This action will delete the pixel"
        confirmButtonText="Delete"
        confirmActionVariant="destructive"
        loading={deleteLoading}
      />
    </>
  );
};
