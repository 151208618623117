import * as Yup from 'yup';
import { ErrorMessages, requiredError } from '../../../constants/error-messages';
import { ApplicationType, CouponType, PurchaseRequirementType } from '../../../graphql';

export const PurchaseRequirementSchema = Yup.object().shape({
  type: Yup.string().default('NONE').required(),
  min_amount: Yup.number().when('type', {
    is: (val: string) => val === PurchaseRequirementType.PurchaseAmount,
    then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Amount')),
    otherwise: (schema) => schema.nullable()
  }),
  max_amount: Yup.number().when(['type', 'show_max_amount'], {
    is: (type: string, showMaxAmount: boolean) => type === PurchaseRequirementType.PurchaseAmount && showMaxAmount,
    then: (schema) =>
      schema
        .min(Yup.ref('min_amount'), ErrorMessages.PURCHASE_REQUIREMENT_MAX_AMOUNT_ERROR)
        .required(requiredError('Maximum amount')),
    otherwise: (schema) => schema.nullable()
  }),
  show_max_amount: Yup.boolean().default(false),
  min_quantity: Yup.number()
    .integer(ErrorMessages.WHOLE_NO_ERROR)
    .when('type', {
      is: (val: string) => val === PurchaseRequirementType.PurchaseQuantity,
      then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Quantity')),
      otherwise: (schema) => schema.nullable()
    }),
  max_quantity: Yup.number().when(['type', 'show_max_quantity'], {
    is: (type: string, showMaxQuantity: boolean) =>
      type === PurchaseRequirementType.PurchaseQuantity && showMaxQuantity,
    then: (schema) =>
      schema
        .integer(ErrorMessages.WHOLE_NO_ERROR)
        .min(Yup.ref('min_quantity'), ErrorMessages.PURCHASE_REQUIREMENT_MAX_QUANTITY_ERROR)
        .required(requiredError('Maximum quantity')),
    otherwise: (schema) => schema.nullable()
  }),
  show_max_quantity: Yup.boolean().default(false),
  productType: Yup.string().default('PURCHASE_CART')
});

export const DiscountSchema = Yup.object({
  code: Yup.string().required(requiredError('Discount code')),
  title: Yup.string().when('has_title', {
    is: true,
    then: (schema) => schema.required(requiredError('Discount title')),
    otherwise: (schema) => schema.nullable()
  }),
  has_title: Yup.boolean().default(false),
  applies_on_each_item: Yup.boolean().default(false),
  disable_cod: Yup.boolean().default(false),
  application_type: Yup.string().default(ApplicationType.Code).required(),
  coupon_type: Yup.string().default(CouponType.CartAmount).required(),
  purchase_requirement: PurchaseRequirementSchema,
  user_order_requirement: Yup.object().shape({
    type: Yup.string().default('all').required(),
    min_order_count: Yup.number()
      .integer(ErrorMessages.WHOLE_NO_ERROR)
      .when('type', {
        is: (val: string) => val === 'order',
        then: (schema) =>
          schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Minimum order count')),
        otherwise: (schema) => schema.nullable()
      }),
    max_order_count: Yup.number().when(['type', 'max_order_count_needed'], {
      is: (type: string, maxOrderCountNeeded: boolean) => type === 'order' && maxOrderCountNeeded,
      then: (schema) =>
        schema
          .integer(ErrorMessages.WHOLE_NO_ERROR)
          .min(Yup.ref('min_order_count'), ErrorMessages.USER_ORDER_REQUIREMENT_MAX_ERROR)
          .required(requiredError('Maximum order count')),
      otherwise: (schema) => schema.nullable()
    }),
    max_order_count_needed: Yup.boolean().default(false)
  }),
  total_usage_limit: Yup.number()
    .integer(ErrorMessages.WHOLE_NO_ERROR)
    .when('show_total_usage_limit', {
      is: true,
      then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Total usage limit')),
      otherwise: (schema) => schema.nullable()
    }),
  show_total_usage_limit: Yup.boolean().default(false),
  per_customer_usage_limit: Yup.number()
    .integer(ErrorMessages.WHOLE_NO_ERROR)
    .when('show_per_customer_usage_limit', {
      is: true,
      then: (schema) =>
        schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Per customer usage limit')),
      otherwise: (schema) => schema.nullable()
    }),
  show_per_customer_usage_limit: Yup.boolean().default(false),
  discoverable: Yup.boolean().default(false),
  combine: Yup.boolean().default(false),
  activation_period: Yup.object().shape({
    start: Yup.date().required(requiredError('Start Date')),
    end: Yup.date()
      .when('has_endTime', {
        is: true,
        then: (schema) => schema.required('End date is required'),
        otherwise: (schema) => schema.nullable()
      })
      .test('is-greater', ErrorMessages.END_DATE_TIME_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent }: any = this;
        if (parent.has_endTime) {
          return val && parent.start && new Date(val) > new Date(parent.start);
        } else return true;
      }),
    has_endTime: Yup.boolean().default(false)
  })
});
