import axios, { AxiosResponse } from 'axios';
import { dashboardAPI } from '..';

// const dashboardAPI = axios.create({
//   baseURL: 'https://api.flexype.dev'
// });
//
// dashboardAPI.interceptors.request.use((config) => {
//   const token = localStorage.getItem('access_token') || '';
//   config.headers.Authorization = token;
//   return config;
// });

export const pinCodesUpload = async (data: FormData): Promise<AxiosResponse<any>> => {
  return dashboardAPI.post('/v1/dashboard/upload-resource', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
