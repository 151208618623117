import React from 'react';
import { Card } from '../../../flexyui';
import IntegrationItem from './integration-item';
import { AnalyticsQuery } from '../../../../graphql';
import { ReactComponent as MetaSVG } from '../../../../assets/images/facebook-svg.svg';
import { ReactComponent as GoogleAdsSVG } from '../../../../assets/images/google-ads.svg';
import { ReactComponent as GoogleAnalyticsSVG } from '../../../../assets/images/google-analytics.svg';

type Props = {
  analyticsData: AnalyticsQuery | undefined;
  loading: boolean;
};

export const IntegrationsOptions: React.FC<Props> = ({ analyticsData, loading }) => {
  return (
    <Card className="h-fit overflow-hidden">
      <IntegrationItem
        loading={loading}
        icon={<MetaSVG />}
        integration="META_PIXELS"
        serviceName="Meta Pixels"
        route="/settings/integrations/meta"
        enabled={analyticsData?.analytics?.meta?.enabled || false}
      />
      <hr />
      <IntegrationItem
        loading={loading}
        icon={<GoogleAnalyticsSVG />}
        integration="GOOGLE_ANALYTICS"
        serviceName="Google Analytics"
        route="/settings/integrations/google-analytics"
        enabled={analyticsData?.analytics?.google_analytics?.enabled || false}
      />
      <hr />
      <IntegrationItem
        loading={loading}
        icon={<GoogleAdsSVG />}
        integration="GOOGLE_ADS"
        serviceName="Google Ads"
        route="/settings/integrations/google-ads"
        enabled={analyticsData?.analytics?.google_ads?.enabled || false}
      />
    </Card>
  );
};
