import classNames from 'classnames';
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Card, Chip, Label, RadioGroup, RadioGroupItem } from '../flexyui';
import { ShippingVersionType, useUpdateShippingVersionMutation } from '../../graphql';
import ConfirmationDialog from '../../components/shared/confirmation-dialog/confirmation-dialog';

type Props = {
  shippingVersion: ShippingVersionType | undefined;
  shippingVersionRefetch: () => void;
};

export const ShippingMigration: React.FC<Props> = ({ shippingVersion, shippingVersionRefetch }) => {
  const [updateShippingVersion] = useUpdateShippingVersionMutation();
  const [changeShippingVersion, setChangeShippingVersion] = useState<ShippingVersionType>(
    shippingVersion || ShippingVersionType.V2
  );
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const handleVersionChange = async () => {
    try {
      await updateShippingVersion({
        variables: {
          configs: {
            version: changeShippingVersion
          }
        }
      });
      enqueueSnackbar(
        `Switched successfully to ${changeShippingVersion === ShippingVersionType.V2 ? 'new' : 'old'} version of shipping!`,
        {
          variant: 'success'
        }
      );

      shippingVersionRefetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <Card className="overflow-hidden border border-[#B49802] mb-6">
        <div className="bg-[#f7f5ec] border-b border-[#B49802] p-4">
          <div className="font-medium text-base">Migrate to new shipping experience!</div>
          <div className="text-[#595F74] mt-2">
            Classic shipping is being depreciated. Please migrate to the new shipping experience before
            <span className="font-medium text-black"> 31st December, 2024</span> for an uninterrupted checkout
            experience.
          </div>
        </div>
        <div className="p-4">
          <div>Choose which version of shipping to use</div>
          <RadioGroup
            value={shippingVersion}
            className="flex flex-col items-start md:flex-row md:items-center gap-4 mt-3"
            defaultValue={ShippingVersionType.V2}
            onValueChange={(value: ShippingVersionType) => {
              setChangeShippingVersion(value);
              setShowConfirmationDialog(true);
            }}
          >
            <div className="flex items-center space-x-2">
              <Label
                htmlFor={ShippingVersionType.V2}
                className={classNames('h-fit border border-border p-4 rounded-xl cursor-pointer', {
                  'border-primary': shippingVersion === ShippingVersionType.V2
                })}
              >
                <div className="">
                  <div className="flex items-center h-full space-x-2">
                    <RadioGroupItem value={ShippingVersionType.V2} id={ShippingVersionType.V2} />
                    <div className="font-medium">New</div>
                    <Chip text="Recommended" variant="tertiary" />
                  </div>
                  <div className="max-w-[360px] text-[#595F74] font-normal mt-2">
                    Sync shipping profiles, zones and rates from Shopify. Create pincode based zones.
                  </div>
                </div>
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <Label
                htmlFor={ShippingVersionType.V1}
                className={classNames('h-fit border border-border p-4 rounded-xl cursor-pointer', {
                  'border-primary': shippingVersion === ShippingVersionType.V1
                })}
              >
                <div className="">
                  <div className="flex items-center h-full space-x-2">
                    <RadioGroupItem value={ShippingVersionType.V1} id={ShippingVersionType.V1} />
                    <div className="font-medium">Classic</div>
                  </div>
                  <div className="max-w-[360px] text-[#595F74] font-normal mt-2">
                    Individual shipping rates. No support for profiles, zones and does not sync shipping rules from
                    shopify.
                  </div>
                </div>
              </Label>
            </div>
          </RadioGroup>
          <div className="mt-3">Please connect with our team for any doubts or concerns.</div>
        </div>
      </Card>
      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleVersionChange}
        text={
          changeShippingVersion === ShippingVersionType.V1
            ? 'The old version you are switching to will be deprecated soon. We recommend staying on the new version for a better experience. Are you sure you want to proceed?'
            : 'Switching to the latest version for a better experience. Before proceeding, please confirm that new shipping rates have been added.'
        }
        confirmButtonText="Confirm"
        confirmActionVariant="default"
      />
    </>
  );
};
