export const modifyCollectionData = (collections: any) => {
  const modifiedCollections = collections?.map((each: any) => {
    const { collection } = each;
    return collection;
  });
  return modifiedCollections || [];
};

export const codAvailabilityConfigDataHandler = (values: any, addedUsers: any, removedUsers: any) => {
  const body = { ...values.codAvailabilityConfig };

  delete body.pincode_filter.resource_item;

  delete body.collection_filter.collections;
  delete body.collection_filter.collection_count;
  if (!body?.collection_filter?.added_collections) body.collection_filter.added_collections = [];
  if (!body?.collection_filter?.removed_collections) body.collection_filter.removed_collections = [];

  delete body.user_filter.users;
  delete body.user_filter.user_count;
  body.user_filter.added_users = addedUsers?.map((each: any) => {
    return {
      phone_number: each?.phone_number
    };
  });
  body.user_filter.removed_users = removedUsers?.map((each: any) => {
    return {
      phone_number: each?.phone_number
    };
  });

  return body;
};

export const partialCodDataHandler = (values: any) => {
  const body = { ...values.partialCod };

  if (body?.reference_amount === body?.PartialReferenceCodAmount?.Cod) body.prepayable_quantity = null;

  return body;
};
