import * as Yup from 'yup';

export const MetaPixelSchema = Yup.object().shape({
  enabled: Yup.boolean().required(),
  pixel_id: Yup.string().required('Pixel ID is required'),
  conversion_api_token: Yup.string().required('Conversion API Token is required'),
  conversion_api_enabled: Yup.boolean().required()
});

export const GoogleAdsSchema = Yup.object().shape({
  enabled: Yup.boolean().required(),
  conversion_id: Yup.string().required('Conversion ID is required'),
  conversion_label: Yup.string().required('Conversion API Token is required'),
  conversion_status: Yup.boolean().required()
});

export const GoogleAnalyticsSchema = Yup.object().shape({
  enabled: Yup.boolean().required(),
  measurement_id: Yup.string().required('Measurement ID is required'),
  api_secret: Yup.string().required('API Secret is required')
});
