import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { Formik, Form as FormikForm } from 'formik';
import { InputTextField, PasswordTextfield } from '../../components/shared';
import { Button, Card, Label } from '../../components/flexyui';
import { translations } from './i18n';
import { AppDispatch } from '../../store';
import { useAuth } from '../../context/auth';
import { isValidEmail } from '../../utils/validation';
import { setStore, setStoreName } from '../../store/main-slice';
import { loginWithEmail } from '../../network/authentication/authentication';
import { ReactComponent as Logo } from '../../assets/images/flexype-logo.svg';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';
import { posthogIdentity } from '../../utils/log-event';

type Props = {
  setForgotPasswordActive: (toggle: boolean) => void | null;
};

interface LoginProps {
  email: string;
  password: string;
}

export const Login: React.FC<Props> = ({ setForgotPasswordActive }) => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const { setAccessToken } = useAuth();
  const [showHelperText, setShowHelperText] = useState(false);

  const loginMutation = useMutation({
    mutationFn: loginWithEmail,
    onError: () => {
      enqueueSnackbar('Incorrect email id or password', {
        variant: 'error'
      });
    },
    onSuccess: (response) => {
      const token = response?.data?.data?.token_data?.access_token;
      localStorage.setItem('access_token', token);

      if (token) {
        setAccessToken(token);
        const user: any = jwtDecode(token); // decode the token here
        dispatch(setStore(user.payload.merchant_id));
        dispatch(setStoreName(user.payload.merchant_name));
        posthogIdentity({
          merchantId: user.payload.merchant_id,
          merchantName: user.payload.merchant_name
        });
        localStorage.setItem('store_name', user.payload.merchant_name);
        localStorage.setItem('merchant_id', user.payload.merchant_id);
      }
    }
  });

  useEffect(() => {
    navigate('');
  }, []);

  const validateEmail = (email: string) => {
    if (!isValidEmail(email)) {
      setShowHelperText(true);
      return false;
    }

    setShowHelperText(false);
    return true;
  };

  const handleForgotPasswordClick = () => {
    setForgotPasswordActive(true);
  };

  function onSubmit(data: LoginProps) {
    validateEmail(data.email);
    loginMutation.mutate(data);
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, email: string, password: string) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      onSubmit({
        email,
        password
      });
    }
  };

  return (
    <div className="px-4 flex flex-col items-center justify-center h-[100dvh]">
      <Logo />

      <Card className="shadow-[0px_4px_20px_rgba(0,0,0,0.05)] p-[26px] min-w-[350px] autofill:bg-primary mt-8 sm:mt-14">
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          onSubmit={async (values) => {
            onSubmit(values);
          }}
        >
          {(props) => (
            <FormikForm>
              <Label>{translations.EMAIL.value}</Label>
              <div className="mb-4">
                <InputTextField
                  type="email"
                  name="email"
                  handleKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    handleKeyDown(e, props.values.email, props.values.password)
                  }
                />
              </div>

              <Label>{translations.PASSWORD.value}</Label>
              <div className="mb-4">
                <PasswordTextfield
                  type="password"
                  name="password"
                  handleKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    handleKeyDown(e, props.values.email, props.values.password)
                  }
                />
              </div>

              <div
                className="ml-auto underline hover:no-underline w-fit mb-5"
                style={{ cursor: 'pointer' }}
                onClick={handleForgotPasswordClick}
              >
                Forgot Password?
              </div>
              <Button type="submit" disabled={props.values.email === '' || props.values.password === ''}>
                {loginMutation.isLoading ? (
                  <LoadingIcon height={16} className={classNames('-ml-1 mr-3 h-5 w-5 animate-spin text-white')} />
                ) : (
                  translations.LOGIN.value
                )}
              </Button>
            </FormikForm>
          )}
        </Formik>
      </Card>
    </div>
  );
};
