import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from './dashboard/dashboard';
import { Orders } from './orders/orders';
import { Shipping } from './shipping/shipping';
import { Coupons } from './coupons/coupons';
import { Settings } from './settings/settings';
import { Customization } from './customization/customization';
import { Upsell } from './upsell/upsell';
import { PaymentOffers } from './payment-offers/payment-offers';
import RTOSuite from './rto-suite/rto-suite';

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<Dashboard />} path="/" />
      <Route element={<Orders />} path="/orders" />
      <Route element={<Shipping />} path="/shipping/*" />
      <Route element={<Coupons />} path="/coupons" />
      <Route element={<Settings />} path="/settings/*" />
      <Route element={<Customization />} path="/customization/*" />
      <Route element={<RTOSuite />} path="/rto-suite" />
      <Route element={<PaymentOffers />} path="/payment-offers" />
      <Route element={<Upsell />} path="/upsell/*" />
    </Routes>
  );
};
