import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import 'react-date-range/dist/styles.css';
import { enqueueSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { Chip } from '../../flexyui/Chip/index';
import 'react-date-range/dist/theme/default.css';
import * as Unicons from '@iconscout/react-unicons';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DiscountFields } from '../../../types/coupon-data-types';
import { CouponType, DiscountType, PurchaseActivityType, PurchaseRequirementType } from '../../../graphql';

dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

type props = {
  activeStep: number;
  selectedCoupon: any | null;
  isEdit: boolean;
};

export const CreateDiscountPreview: FC<props> = ({ activeStep, selectedCoupon, isEdit }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  let values: any = null;
  if (useFormikContext()?.values) {
    values = useFormikContext()?.values;
  } else {
    const val = { ...selectedCoupon };
    val.user_order_requirement = {
      max_order_count: selectedCoupon?.user_order_requirement?.max_order_count || null,
      min_order_count: selectedCoupon?.user_order_requirement?.min_order_count || null,
      type:
        selectedCoupon?.user_order_requirement?.max_order_count === 0 &&
        selectedCoupon?.user_order_requirement?.min_order_count === null
          ? 'first'
          : selectedCoupon?.user_order_requirement?.min_order_count >= 0 &&
              selectedCoupon?.user_order_requirement?.min_order_count !== null
            ? 'order'
            : 'all'
    };
    values = val;
  }

  function generatePurchaseMessage(purchaseRequirement: DiscountFields['purchase_requirement']) {
    const minAmount = purchaseRequirement.min_amount ? purchaseRequirement.min_amount : 0;
    const maxAmount = purchaseRequirement.max_amount ? purchaseRequirement.max_amount : 0;
    const minQuantity = purchaseRequirement.min_quantity ? purchaseRequirement.min_quantity : 0;
    const maxQuantity = purchaseRequirement.max_quantity ? purchaseRequirement.max_quantity : 0;
    const productType = (itemText: string) => {
      if (isEdit)
        return purchaseRequirement?.productType === 'PURCHASE_PRODUCTS'
          ? 'specific product(s)'
          : purchaseRequirement?.productType === 'PURCHASE_COLLECTIONS'
            ? 'specific collection(s)'
            : itemText;
      else
        return purchaseRequirement?.products?.length > 0
          ? 'specific product(s)'
          : purchaseRequirement?.collections?.length > 0
            ? 'specific collection(s)'
            : itemText;
    };

    if (isEdit) {
      switch (purchaseRequirement.type) {
        case 'NONE':
          return 'Applicable on all carts';
        case 'PURCHASE_AMOUNT':
          return purchaseRequirement.show_max_amount
            ? `For purchases of any ${productType('item')} worth ₹${minAmount} to ₹${maxAmount}`
            : `Minimum purchase of ₹${minAmount} on ${productType('any item')}`;
        case 'PURCHASE_QUANTITY':
          return purchaseRequirement.show_max_quantity
            ? `For purchases with any ${minQuantity} to ${maxQuantity} ${productType('item(s)')}`
            : `Minimum purchase of any ${minQuantity} ${productType('item(s)')}`;
        default:
          return '';
      }
    } else {
      switch (purchaseRequirement.type) {
        case 'NONE':
          return 'Applicable on all carts';
        case 'PURCHASE_AMOUNT':
          return maxAmount
            ? `For purchases of any ${productType('item')} worth ₹${minAmount} to ₹${maxAmount}`
            : `Minimum purchase of ₹${minAmount} on ${productType('any item')}`;
        case 'PURCHASE_QUANTITY':
          return maxQuantity
            ? `For purchases with any ${minQuantity} to ${maxQuantity} ${productType('item(s)')}`
            : `Minimum purchase of any ${minQuantity} ${productType('item(s)')}`;
        default:
          return '';
      }
    }
  }

  function generateUserOrderMessage(values: any) {
    const type = values.user_order_requirement.type;
    const minOrderCount = values.user_order_requirement.min_order_count || 0;
    const maxOrderCount = values.user_order_requirement.max_order_count || 0;

    if (values?.user_specific_metadata?.resource_id) return 'For specific customers';

    switch (type) {
      case 'all':
        return 'For all customers';
      case 'first':
        return 'For first-time customers';
      case 'order':
        if (isEdit) {
          if (!values.user_order_requirement.max_order_count_needed) {
            return `For customers with a minimum of ${minOrderCount} order(s)`;
          } else if (minOrderCount && maxOrderCount) {
            return `For customers with ${minOrderCount} to ${maxOrderCount} order(s)`;
          } else {
            return `For customers with a minimum of ${minOrderCount} order(s)`;
          }
        } else {
          if (!values.user_order_requirement.max_order_count) {
            return `For customers with a minimum of ${minOrderCount} order(s)`;
          } else if (minOrderCount && maxOrderCount) {
            return `For customers with ${minOrderCount} to ${maxOrderCount} order(s)`;
          } else {
            return `For customers with a minimum of ${minOrderCount} order(s)`;
          }
        }
      default:
        return '';
    }
  }

  function renderUsageLimit(label: string, value: number | string, suffix = '') {
    return (
      <li className="ml-4">
        {value !== null && value !== undefined && value !== '' && value !== 0
          ? `${label} ${value} ${suffix}`
          : `${label} ${suffix}`}
      </li>
    );
  }

  const getUseOrder = `${
    values?.application?.max_per_order ? `and ${values?.application?.max_per_order} uses per order` : ''
  }`;

  function bxgyConditionPreview(values: any) {
    const discountType = isEdit ? values?.application?.discount_type : values?.discount_type;
    const discountAmount = isEdit ? values?.application?.discount_amount : values?.discount_amount;

    if (
      values.purchase_requirement.type !== 'NONE' &&
      values.purchase_requirement.type === PurchaseRequirementType.PurchaseQuantity &&
      values.purchase_requirement.min_quantity &&
      values.application.min_quantity
    ) {
      return `Buy ${values.purchase_requirement.min_quantity} ${
        values.purchase_requirement.min_quantity > 1 ? 'items' : 'item'
      } Get
         ${values.application.min_quantity} ${values.application.min_quantity > 1 ? 'items' : 'item'} ${
           (discountType === DiscountType.Percentage && discountAmount === 100) || discountType === 'FREE'
             ? 'free'
             : discountAmount
               ? discountType === DiscountType.Percentage
                 ? `at ${discountAmount?.toFixed(2)}% off ${values.application.min_quantity > 1 ? 'each' : ''}`
                 : `at ₹${discountAmount?.toFixed(2)}`
               : ''
         } ${isEdit && !values?.application?.show_max_per_order ? '' : getUseOrder}`;
    }
    if (
      values.purchase_requirement.type !== 'NONE' &&
      values.purchase_requirement.type === PurchaseRequirementType.PurchaseAmount &&
      values.purchase_requirement.min_amount &&
      values.application.min_quantity
    ) {
      return `Spend ₹${values.purchase_requirement.min_amount?.toFixed(2)} Get
         ${values.application.min_quantity} ${values.application.min_quantity > 1 ? 'items' : 'item'} ${
           (discountType === DiscountType.Percentage && discountAmount === 100) || discountType === 'FREE'
             ? 'free'
             : discountAmount
               ? discountType === DiscountType.Percentage
                 ? `at ${discountAmount?.toFixed(2)}% off ${values.application.min_quantity > 1 ? 'each' : ''}`
                 : `at ₹${discountAmount?.toFixed(2)}`
               : ''
         } ${isEdit && !values?.application?.show_max_per_order ? '' : getUseOrder}`;
    }
  }

  function purchaseDrivenPreview(values: any) {
    switch (values?.purchase_activity?.type) {
      case PurchaseActivityType.CouponCode:
        return values?.purchase_activity?.coupon_usage?.coupon?.code
          ? `For customers who have previously used the 
              ${values?.purchase_activity?.coupon_usage?.coupon?.code} coupon.`
          : '';
      case PurchaseActivityType.OrderAmount:
        return `For customers who have made purchases of ₹${values?.purchase_activity?.min_amount || 0} or more.`;
      case PurchaseActivityType.OrderCount:
        return `For customers who have placed at least ${values?.purchase_activity?.min_quantity || 0} order(s)`;
      default:
        return '';
    }
  }

  const formatDateTime = (dateTime: string) => {
    return dayjs(dateTime).format('Do MMM YYYY [at] h:mm A');
  };

  const getActualStep = (step: number) => (values.coupon_type === CouponType.PurchaseHistory ? step : step - 1);

  return (
    <>
      <div className="h-fit resize-none border rounded-[14px] w-full sm:w-[20rem]">
        {values && (
          <>
            <div
              className="font-semibold p-2 flex items-center"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div className="mr-2">{values?.code ? values.code.toUpperCase() : 'No discount code yet'}</div>
              {!isEdit && values?.code && isHovered && (
                <Unicons.UilCopy
                  size={16}
                  className="text-[#121b38] cursor-pointer z-[1000]"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(values?.code);
                    enqueueSnackbar('Copied to clipboard', {
                      variant: 'success'
                    });
                  }}
                />
              )}
            </div>
            <hr />
            <div className="font-semibold p-2">Discount Properties</div>
            <div className="text-[#888D9B] pt-2 px-2">SETUP</div>
            <ul className=" list-disc p-2">
              {values.coupon_type === CouponType.Bxgy ? <li className="ml-4 ">Buy X get Y</li> : null}
              {values.coupon_type === CouponType.PurchaseHistory ? <li className="ml-4 ">Purchase Driven</li> : null}
              {values.coupon_type !== CouponType.Bxgy ? (
                <li className=" ml-4 ">
                  {values.discount_type === 'PERCENTAGE' ? '' : '₹'}
                  {values.discount_amount || 0}
                  {values.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                  {!!values.discount_max_cap &&
                    values.discount_type === 'PERCENTAGE' &&
                    values.show_discount_max_cap &&
                    `upto ₹${values?.discount_max_cap === true ? 0 : values?.discount_max_cap}`}
                  {values.application.type === 'UNIVERSAL' && ' on total value'}
                  {values.application.type === 'PRODUCTS' && ' on specific product(s)'}
                  {values.application.type === 'COLLECTIONS' && ' on specific collection(s)'}
                </li>
              ) : null}
              <li className=" ml-4">
                {!values.discoverable
                  ? 'Coupon will not be shown to any customer'
                  : 'Coupon will be shown to eligible customers'}
              </li>
            </ul>
            {activeStep >= 1 && values.coupon_type === CouponType.PurchaseHistory && (
              <>
                <div className="text-[#888D9B] pt-2 px-2">USER BEHAVIOUR</div>

                <ul className="list-disc p-2">
                  {isEdit && values.purchase_activity_period?.start && (
                    <li className="ml-4">
                      For customers who have made purchases from{' '}
                      {formatDateTime(values.purchase_activity_period?.start)}
                      {values.purchase_activity_period?.has_endTime &&
                        values.purchase_activity_period?.end &&
                        ` to ${formatDateTime(values.purchase_activity_period?.end)}`}
                    </li>
                  )}
                  {!isEdit && values.purchase_activity_period?.start && (
                    <li className="ml-4">
                      For customers who have made purchases from{' '}
                      {formatDateTime(values.purchase_activity_period?.start)}
                      {!!values.purchase_activity_period?.end &&
                        ` to ${formatDateTime(values.purchase_activity_period?.end)}`}
                    </li>
                  )}
                  {purchaseDrivenPreview(values)?.length > 0 ? (
                    <li className="ml-4">{purchaseDrivenPreview(values)}</li>
                  ) : null}
                </ul>
              </>
            )}
            {activeStep >= getActualStep(2) && (
              <>
                <div className="text-[#888D9B] pt-2 px-2">CONDITION</div>

                <ul className=" list-disc p-2">
                  {values.coupon_type === CouponType.Bxgy ? (
                    <li className="ml-4">{bxgyConditionPreview(values)}</li>
                  ) : null}
                  {values.coupon_type !== CouponType.Bxgy ? (
                    <li className=" ml-4 ">{generatePurchaseMessage(values.purchase_requirement)}</li>
                  ) : null}
                </ul>
              </>
            )}
            {activeStep >= getActualStep(3) && (
              <>
                <div className="text-[#888D9B]  pt-2 px-2">ELIGIBILITY</div>
                <ul className="list-disc p-2">
                  <li className="ml-4 ">{generateUserOrderMessage(values)}</li>
                </ul>
              </>
            )}
            {activeStep >= getActualStep(4) && (
              <>
                <div className="text-[#888D9B] pt-2 px-2">LIMITS</div>
                <ul className="list-disc p-2">
                  {values.disable_cod && <li className="ml-4">COD disabled</li>}
                  {isEdit &&
                    values.show_total_usage_limit &&
                    renderUsageLimit('Limit to', values.total_usage_limit, 'use(s) in total')}
                  {isEdit &&
                    values.show_per_customer_usage_limit &&
                    renderUsageLimit('Limit to', values.per_customer_usage_limit, 'use(s) per customer')}
                  {isEdit &&
                    !values.show_total_usage_limit &&
                    !values.show_per_customer_usage_limit &&
                    renderUsageLimit('No usage limit', '')}
                  {isEdit && values.activation_period?.start && (
                    <li className="ml-4">
                      Coupon is valid from {formatDateTime(values.activation_period?.start)}
                      {values.activation_period?.has_endTime &&
                        values.activation_period?.end &&
                        ` until ${formatDateTime(values.activation_period?.end)}`}
                    </li>
                  )}

                  {!isEdit &&
                    values.total_usage_limit &&
                    renderUsageLimit('Limit to', values.total_usage_limit, 'use(s) in total')}
                  {!isEdit &&
                    values.per_customer_usage_limit &&
                    renderUsageLimit('Limit to', values.per_customer_usage_limit, 'use(s) per customer')}
                  {!isEdit &&
                    !values.total_usage_limit &&
                    !values.per_customer_usage_limit &&
                    renderUsageLimit('No usage limit', '')}
                  {!isEdit && values.activation_period?.start && (
                    <li className="ml-4">
                      Coupon is valid from {formatDateTime(values.activation_period?.start)}
                      {!!values.activation_period?.end && ` until ${formatDateTime(values.activation_period?.end)}`}
                    </li>
                  )}
                </ul>{' '}
              </>
            )}
          </>
        )}
      </div>

      {values?.tags?.length === 0 && (
        <div className="mt-2 mx-4 flex items-center">
          {values?.tags.map((tag: string) => (
            <div key={tag} className="mr-1.5">
              <Chip text={tag} variant="simple" className="text-[#696969]" />
            </div>
          ))}
        </div>
      )}
    </>
  );
};
