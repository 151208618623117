import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ShippingV1 } from '../../components/shipping';
import { ShippingV2 } from '../../components/shipping-v2';
import { useGetShippingVersionQuery } from '../../graphql';
import LayoutWrapper from '../../components/layout/layout-wrapper';
import { Title } from '../../components/shared/dashboard-title/title';
import { ShippingMigration } from '../../components/shipping-v2/shipping-migration';
import { Card, Skeleton, Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/flexyui';

export const Shipping: React.FC = () => {
  const location = useLocation();
  const { data, loading, refetch } = useGetShippingVersionQuery();

  const [activeTab, setActiveTab] = useState<string>('V2');

  useEffect(() => {
    if (!data?.getShippingVersion) return;

    if (data?.getShippingVersion?.onboarded_version === 'V2') {
      setActiveTab('V2');
      return;
    }
    setActiveTab(data?.getShippingVersion?.version);
  }, [data]);

  if (loading && !location.pathname.includes('profile'))
    return (
      <LayoutWrapper>
        <div className="px-4">
          <Title title="Shipping and Delivery" />
          <Card className="flex flex-col gap-4 p-4">
            <div className="flex items-center justify-between">
              <div>
                <Skeleton className="w-[150px] sm:w-[300px] !m-0 !mb-2" />
                <Skeleton className="w-[300px] sm:w-[450px] !m-0" />
              </div>
              <Skeleton className="hidden sm:block w-[100px] h-[40px] !m-0" />
            </div>
            {[0, 1, 3].map(() => (
              <div className="bg-[#FAFAFA] rounded-md border border-[#E3E3E3] p-3">
                <Skeleton className="w-full sm:w-[250px] !m-0 !mb-2" />
                <div className="flex items-center gap-2">
                  <Skeleton className="w-[80px] !m-0" />
                  <Skeleton className="w-[80px] !m-0" />
                </div>
              </div>
            ))}
          </Card>
          <Card className="mt-4 flex flex-col gap-4 p-4">
            <div className="flex items-center justify-between">
              <div>
                <Skeleton className="w-[150px] sm:w-[300px] !m-0 !mb-2" />
                <Skeleton className="w-[300px] sm:w-[450px] !m-0" />
              </div>
              <Skeleton className="hidden sm:block w-[100px] h-[40px] !m-0" />
            </div>
          </Card>
        </div>
      </LayoutWrapper>
    );

  return (
    <>
      <LayoutWrapper>
        <div className="px-4">
          {!location.pathname.includes('profile') && (
            <>
              <Title title="Shipping and Delivery" />
              {data?.getShippingVersion?.onboarded_version === 'V1' && (
                <ShippingMigration
                  shippingVersion={data?.getShippingVersion?.version}
                  shippingVersionRefetch={refetch}
                />
              )}
            </>
          )}

          <Tabs value={activeTab} className="justify-center w-full" onValueChange={(value) => setActiveTab(value)}>
            {!location.pathname.includes('profile') && data?.getShippingVersion?.onboarded_version === 'V1' && (
              <TabsList className="max-w-fit justify-between backdrop-blur-3xl mb-3">
                <TabsTrigger value="V2" className="px-4">
                  New
                </TabsTrigger>
                <TabsTrigger value="V1" className="px-4">
                  Classic
                </TabsTrigger>
              </TabsList>
            )}
            <div className="mb-4">
              <TabsContent value="V2">
                <ShippingV2 />
              </TabsContent>
              <TabsContent value="V1">
                <ShippingV1 />
              </TabsContent>
            </div>
          </Tabs>
        </div>
      </LayoutWrapper>
    </>
  );
};
