import * as Yup from 'yup';

export const ShippingSchema = Yup.object().shape({
  title: Yup.string().required('Name is required'),
  delivery_estimation: Yup.string().required('Delivery estimation is required'),
  enabled: Yup.boolean(),
  type: Yup.string().oneOf(['NONE', 'PRICE', 'WEIGHT']).required('Type is required'),
  price_condition_rules: Yup.array().of(
    Yup.object().shape({
      minimum_value: Yup.number().required('Minimum value is required').min(0, 'Minimum value must be greater than 0'),
      maximum_value: Yup.number()
        .nullable()
        .moreThan(Yup.ref('minimum_value'), 'Maximum value must be greater than minimum value'),
      delivery_price: Yup.number().required('Delivery price is required'),
      cod_accepted: Yup.boolean().required(),
      cod_charges: Yup.number()
        .nullable()
        .when('cod_accepted', {
          is: true,
          then: (schema) => schema.required('Minimum value is required').min(0, 'Minimum value must be greater than 0'),
          otherwise: (schema) => schema.nullable()
        })
    })
  )
});
