import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import CustomizationLayout from '../../dashboard/customizations/layout';
import { AppDispatch, RootState } from '../../../store';
import { setAddressCustomization, setPage } from '../../../store/customization-slice';
import {
  useAddress_Customisation_ConfigQuery as useAddressQuery,
  useUpdateAddressCustomisationMutation
} from '../../../graphql';
import useSaveCancelButtons from '../../../hooks/use-save-cancel';
import ErrorHandling from '../../error-handling/error-handling';
import { enqueueSnackbar } from 'notistack';
import { Label, Separator, Switch } from '../../flexyui';

const AddressTab: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const addressCustomization = useSelector((state: RootState) => state.customization.address);
  const { setShowActions, saveButtonClicked, cancelButtonClicked, setLoadingActions } = useSaveCancelButtons();
  const { data: addressData, loading: addressLoading, error: addressError, refetch } = useAddressQuery();
  const [updateLoginCustomisationMutation] = useUpdateAddressCustomisationMutation();
  const [bannerCards, setBannerCards] = useState<any>([]);

  useEffect(() => {
    if (addressData?.customisations?.address_customisation_config) {
      dispatch(setAddressCustomization(addressData.customisations.address_customisation_config));
    }
  }, [addressData?.customisations.address_customisation_config]);

  useEffect(() => {
    dispatch(setPage('Address'));
  }, []);

  const handleSave = async () => {
    setLoadingActions(true);
    try {
      await updateLoginCustomisationMutation({
        variables: {
          configs: {
            ...addressCustomization
          }
        }
      });
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
      setLoadingActions(false);
      setShowActions(false);
    }
    await refetch();
    setLoadingActions(false);
    // Don't remove
    // if (addressData?.customisations.address_customisation_config) {
    //   dispatch(setAddressCustomization(addressData?.customisations.address_customisation_config));
    // }
  };

  useEffect(() => {
    if (addressData?.customisations?.address_customisation_config?.banner?.banners) {
      setBannerCards(addressData?.customisations?.address_customisation_config?.banner?.banners);
    }
  }, [addressData]);

  const handleCancel = () => {
    if (addressData?.customisations.address_customisation_config) {
      dispatch(setAddressCustomization(addressData?.customisations.address_customisation_config));
      setBannerCards(addressData?.customisations.address_customisation_config.banner.banners);
    }
  };

  useEffect(() => {
    const hasChanges = !isEqual(addressData?.customisations.address_customisation_config, addressCustomization);
    if (hasChanges) {
      if (!addressLoading) setShowActions(true);
      if (saveButtonClicked) {
        handleSave();
      }
      if (cancelButtonClicked) {
        handleCancel();
      }
    } else {
      setShowActions(false);
    }
  }, [
    addressData?.customisations.address_customisation_config,
    addressCustomization,
    saveButtonClicked,
    cancelButtonClicked
  ]);

  const handleEnableAdditionalAddressLine = (value: boolean) => {
    const data = {
      ...addressCustomization,
      additional_line_address: {
        enabled: value,
        required: value ? addressCustomization.additional_line_address.required : value
      }
    };
    dispatch(setAddressCustomization(data));
  };

  const handleAdditionalAddressLineReqirement = (value: boolean) => {
    const data = {
      ...addressCustomization,
      additional_line_address: {
        ...addressCustomization.additional_line_address,
        required: value
      }
    };
    dispatch(setAddressCustomization(data));
  };

  if (addressError?.networkError) return <ErrorHandling />;

  return (
    <>
      <CustomizationLayout
        tab={'Address'}
        lockCoupons={false}
        loading={addressLoading}
        tabData={addressCustomization}
        placeholder="Proceed to Pay"
        setCustomization={setAddressCustomization}
        cards={bannerCards}
        setCards={setBannerCards}
      />
      <Separator />
      <Label size={'md'}>Address Form</Label>
      <div className="flex items-center gap-2 mt-4">
        <Switch
          id="additional_line_address.enabled"
          checked={addressCustomization?.additional_line_address?.enabled}
          onCheckedChange={handleEnableAdditionalAddressLine}
        />
        <Label size={'paragraph'} className="text-[#595F74]" htmlFor="additional_line_address.enabled">
          Show additional address line
        </Label>
      </div>
      <div className="flex items-center gap-2 mt-3">
        <Switch
          disabled={!addressCustomization?.additional_line_address?.enabled}
          id="additional_line_address.required"
          checked={addressCustomization?.additional_line_address?.required}
          onCheckedChange={handleAdditionalAddressLineReqirement}
        />
        <Label size={'paragraph'} className="text-[#595F74]" htmlFor="additional_line_address.required">
          Make additional address line a required field
        </Label>
      </div>
    </>
  );
};

export default AddressTab;
