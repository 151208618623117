/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useFormikContext } from 'formik';
import * as Unicons from '@iconscout/react-unicons';
import { InputTextField } from '../shared';
import { Button, Checkbox, Input, Label } from '../flexyui';
import { ShippingType } from '../../graphql';

type PriceProps = {
  index: number;
  ruleIndex: number;
  deleteDisabled?: boolean;
  handleDelete: (index: number) => void;
  errors?: any;
  setFieldValue?: any;
};

export const ShippingRuleRow: React.FC<PriceProps> = ({
  index,
  handleDelete,
  ruleIndex,
  deleteDisabled,
  errors,
  setFieldValue
}) => {
  const minName = `minimum_order_price_${ruleIndex}`;
  const maxName = `maximum_order_price_${ruleIndex}`;
  const deliveryPriceName = `delivery_price_${ruleIndex}`;
  const codAccepted = `cod_accepted_${ruleIndex}`;
  const codCharges = `cod_charges_${ruleIndex}`;

  const { values, getFieldProps }: any = useFormikContext();
  return (
    <>
      <div className="flex justify-between items-center px-4 pt-4 font-normal">
        <div className="max-w-[138px]">
          <Input
            {...getFieldProps(minName)}
            type="number"
            disabled={index > 0}
            onChange={(e: any) => {
              const value = e.target.value;
              const regex = /^\d*\.?\d{0,2}$/;
              if (regex.test(value)) setFieldValue(minName, value ? Number(value) : '');
            }}
            placeholder={values.type === ShippingType.Price ? 'Min Price' : 'Min Weight'}
            defaultValue={0}
            iconPosition={values.type === ShippingType.Price ? 'start' : 'end'}
            icon={
              values.type === ShippingType.Price ? (
                <Unicons.UilRupeeSign width={18} height={18} className="mr-3 text-[#2A324C]" />
              ) : (
                <p className="text-[#2A324C] font-medium">Kg</p>
              )
            }
          />
        </div>
        <div>
          <Unicons.UilMinus width={18} height={18} className="text-[#2A324C]" />
        </div>
        <div className="max-w-[138px]">
          <Input
            {...getFieldProps(maxName)}
            type="number"
            onChange={(e: any) => {
              const value = e.target.value;
              const regex = /^\d*\.?\d{0,2}$/;
              if (regex.test(value)) setFieldValue(maxName, value ? Number(value) : '');
            }}
            placeholder={values.type === ShippingType.Price ? 'Any Price' : 'Any Weight'}
            minPrice={0}
            error={!!errors[maxName]}
            errorMessage={values.type === ShippingType.Price ? 'Max price is required' : 'Max weight is required'}
            iconPosition={values.type === ShippingType.Price ? 'start' : 'end'}
            icon={
              values.type === ShippingType.Price ? (
                <Unicons.UilRupeeSign width={18} height={18} className="mr-3 text-[#2A324C]" />
              ) : (
                <p className="text-[#2A324C] font-medium">Kg</p>
              )
            }
          />
        </div>
        <div className="hidden sm:block max-w-[138px]">
          <InputTextField
            type="number"
            name={deliveryPriceName}
            placeholder="Free"
            minPrice={'0'}
            icon={<Unicons.UilRupeeSign width={18} height={18} className="mr-3 text-[#2A324C]" />}
          />
        </div>
        <Button
          size="icon"
          variant="icon"
          disabled={deleteDisabled}
          onClick={() => handleDelete(ruleIndex)}
          className="ml-2 sm:ml-0"
        >
          <Unicons.UilTimes className="text-[#000] cursor-pointer" />
        </Button>
      </div>
      <div className="mt-4">
        <div className="px-4 mb-4 sm:hidden">
          <div className="text-[#595F74] font-medium mb-1.5">Shipping Charges</div>
          <div className="max-w-[138px]">
            <InputTextField
              type="number"
              name={deliveryPriceName}
              placeholder="Free"
              minPrice={'0'}
              icon={<Unicons.UilRupeeSign width={18} height={18} className="mr-3 text-[#2A324C]" />}
            />
          </div>
        </div>
        <div className="items-center flex ml-5 mb-4">
          <Checkbox
            checked={values[codAccepted]}
            id={codAccepted}
            name={codAccepted}
            onChange={(e) => setFieldValue(codAccepted, e.target.checked)}
          />
          <div className="grid gap-[5px] leading-none ml-3">
            <Label htmlFor={codAccepted} className="cursor-pointer">
              Accept cash on delivery
            </Label>
          </div>
        </div>

        {values[codAccepted] && (
          <div className="max-w-[180px] ml-4 mb-5 font-normal">
            <InputTextField
              type="number"
              name={codCharges}
              placeholder="COD Charges"
              minPrice={'0'}
              icon={<Unicons.UilRupeeSign width={18} height={18} className="mr-3 text-[#2A324C]" />}
            />
          </div>
        )}
      </div>
    </>
  );
};
