/* eslint-disable camelcase */
import {
  GoogleAdsConversionAction,
  useDeleteGoogleAdsMutation,
  useUpdateGoogleAdsMutation
} from '../../../../../graphql';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Checkbox, Chip, Label } from '../../../../flexyui';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/confirmation-dialog';

type Props = {
  conversion: GoogleAdsConversionAction;
  handleEditClick: (value: GoogleAdsConversionAction) => void;
  handleGoogleAdsConversionStatus: () => void;
  refetch: () => void;
};

export const GoogleAdsCard: React.FC<Props> = ({
  conversion,
  handleEditClick,
  handleGoogleAdsConversionStatus,
  refetch
}) => {
  const [updateConversion] = useUpdateGoogleAdsMutation();
  const [deleteConversion, { loading: deleteLoading }] = useDeleteGoogleAdsMutation();

  const [conversionStatus, setConversionStatus] = useState<boolean>(conversion.enabled);
  const [conversionToDelete, setConversionToDelete] = useState<GoogleAdsConversionAction | null>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  useEffect(() => {
    if (conversion) setConversionStatus(conversion.enabled);
  }, [conversion]);

  const handleConversionStatusUpdate = async (value: boolean) => {
    setConversionStatus(value);

    const { id, ...rest } = conversion;

    try {
      await updateConversion({
        variables: {
          configs: {
            ...rest,
            enabled: value
          },
          updateGoogleAdsId: conversion.id
        }
      });
      enqueueSnackbar(`Conversion has been ${value ? 'enabled' : 'disabled'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });

      setConversionStatus(conversion.enabled);
    }
  };

  const handleDeleteConversion = async () => {
    if (!conversionToDelete) return;

    try {
      handleGoogleAdsConversionStatus();
      await deleteConversion({
        variables: {
          deleteGoogleAdsId: conversionToDelete.id
        }
      });

      enqueueSnackbar('Conversion has been deleted successfully!', {
        variant: 'success'
      });

      refetch();
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setConversionToDelete(null);
      setShowConfirmationDialog(false);
    }
  };

  return (
    <>
      <div className="text-[#888D9B] text-xs border border-muted px-4 py-4 rounded-[6px] mb-3 last:mb-0">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Checkbox
              checked={conversionStatus}
              id={'conversion_enabled'}
              name={'conversionEnabled'}
              onChange={(e) => handleConversionStatusUpdate(e.target.checked)}
            />
            <div className="grid gap-[5px] leading-none ml-3">
              <Label htmlFor={'conversion_enabled'} size={'paragraph'} className="font-normal text-[#595F74]">
                Enable this conversion
              </Label>
            </div>
          </div>
          <div className="flex items-center">
            <Button size="icon" variant="icon" onClick={() => handleEditClick(conversion)} className="mr-2">
              <Unicons.UilEdit size={'22'} className="p-[2px] text-[#595F74]" />
            </Button>
            <Button
              size="icon"
              variant="icon"
              onClick={() => {
                setConversionToDelete(conversion);
                setShowConfirmationDialog(true);
              }}
              className="text-[#595F74] hover:text-red-500"
            >
              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
            </Button>
          </div>
        </div>
        <div className="mt-3 font-normal text-[#595F74]">Conversion ID</div>
        <div className="flex justify-start text-sm text-[#121B38] font-normal">
          {conversion?.conversion_id ? conversion?.conversion_id : '-'}
        </div>
        <div className="mt-3 flex items-center">
          <div className="font-normal text-[#595F74] mr-2">Conversion API Token</div>
          <Chip
            text={conversion.conversion_status ? 'Enabled' : 'Disabled'}
            variant={conversion.conversion_status ? 'success' : 'draft'}
          />
        </div>
        <div className="max-w-[80%] mt-0.5 text-sm text-[#121B38] font-normal break-all">
          {conversion?.conversion_label ? conversion?.conversion_label : '-'}
        </div>
      </div>

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleDeleteConversion}
        headerText="Delete Conversion?"
        text="This action will delete the conversion"
        confirmButtonText="Delete"
        confirmActionVariant="destructive"
        loading={deleteLoading}
      />
    </>
  );
};
