/* eslint-disable no-template-curly-in-string */
import React, { useState, useCallback, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import * as Unicons from '@iconscout/react-unicons';
import { Formik } from 'formik';
import { pricingRange } from '../../constants/shipping';
import { ShippingRuleRow } from './shipping-rule-row';
import { ValidateRules } from '../../utils/shipping/validate-rules';
import { parseRules, validatePriceRangeRule } from '../../utils/shipping/validate-shipping';
import {
  ShippingMethod,
  useUpdateShippingMethodMutation,
  useAddShippingMethodMutation,
  PincodeType,
  ShippingType
} from '../../graphql';
import { InputTextField } from '../shared';
import { Button, Card, Label, RadioGroup, RadioGroupItem } from '../flexyui';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';
import Dropzone from 'react-dropzone';
import { enqueueSnackbar } from 'notistack';
import { pinCodesUpload } from '../../network/shipping/shipping';
import ConfirmationDialog from '../shared/confirmation-dialog/confirmation-dialog';
import { setShippingBackDropClicked, setShippingDiscardDialog } from '../../store/main-slice';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { RadioWithLabel } from '../shared/radio-with-label/radio-with-label';

type Props = {
  shippingData?: ShippingMethod;
  handleShowShippingDetail: () => void;
  refetch: () => void;
};

export const ShippingDetailsNew: React.FC<Props> = ({ shippingData, handleShowShippingDetail, refetch }) => {
  const dispatch = useDispatch<AppDispatch>();
  const shippingDiscardDialog = useSelector((state: RootState) => state.main.shippingDiscardDialog);
  const shippingBackDropClicked = useSelector((state: RootState) => state.main.shippingBackDropClicked);
  const [updateShippingMethodMutation, { loading }] = useUpdateShippingMethodMutation();
  const [addShippingMethodMutation, { loading: addingData }] = useAddShippingMethodMutation();
  const [dragEnter, setDragEnter] = useState<boolean>(false);
  const [dragAccepted, setDragAccepted] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState(false);
  const [rangeRules] = useState(
    shippingData?.price_range_rules?.reduce((acc: any, rule: any, index: number) => {
      const keys = Object.keys(rule);
      let newAcc = { ...acc };
      keys?.forEach((key) => {
        newAcc = {
          ...newAcc,
          [`${key}_${index}`]: rule[key]
        };
      });
      return newAcc;
    }, {})
  );
  const [rows, setRows] = useState(Array.from(Array(shippingData?.price_range_rules?.length || 1).keys()));

  const handleCancel = () => {
    if (!shippingDiscardDialog && !shippingBackDropClicked) {
      dispatch(setShippingBackDropClicked(false));
      dispatch(setShippingDiscardDialog(false));
      handleShowShippingDetail();
    } else {
      dispatch(setShippingBackDropClicked(true));
      dispatch(setShippingDiscardDialog(true));
    }
  };

  const calculateRules = useCallback((values: any) => parseRules(values, rows), [rows]);

  const onAddField = useCallback(
    (values: any, setFieldValue: any, validateForm: any) => {
      const newRowIndex = rows[rows.length - 1] + 1;
      setRows((prev) => [...prev, prev[prev.length - 1] + 1]);
      setFieldValue(
        `minimum_order_price_${newRowIndex}`,
        (Number(values[`maximum_order_price_${newRowIndex - 1}`]) + 0.01).toFixed(2)
      );
      setFieldValue(`maximum_order_price_${newRowIndex}`, null);
      setFieldValue(`cod_accepted_${newRowIndex}`, false);
      setTimeout(() => {
        validateForm();
      }, 10);
    },
    [rows]
  );

  const onDeleteField = (index: any, setFieldValue: any, validateForm: any) => {
    setRows((prev) => prev?.filter((element) => element !== index));
    setFieldValue(`minimum_order_price_${index}`, null);
    setFieldValue(`maximum_order_price_${index}`, null);
    setFieldValue(`delivery_price_${index}`, null);
    setFieldValue(`cod_accepted_${index}`, undefined);
    setFieldValue(`cod_charges_${index}`, 0);

    setTimeout(() => {
      validateForm();
    }, 10);
  };

  const handleSave = async (values: any) => {
    const rules = calculateRules(values);
    const priceRangeRules: any = {
      name: values.name,
      type: values.type,
      enabled: true,
      delivery_estimation: values.delivery_estimation,
      pincode_resource_id: values?.pincode_resource_id,
      pincode_resource_type: values?.pincode_resource_type,
      price_range_rules: rules.map((rule: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { _index, ...rest } = rule;
        return rest;
      })
    };
    if (!shippingData) {
      delete priceRangeRules.enabled;
    }
    // eslint-disable-next-line array-callback-return
    const newPriceRangeRule = priceRangeRules.price_range_rules.map((rule: any, index: number) => {
      if (rule.maximum_order_price === '') {
        // eslint-disable-next-line camelcase
        const { maximum_order_price, ...rest } = rule;
        return rest;
      }
      return rule;
    });
    priceRangeRules.price_range_rules = newPriceRangeRule;

    try {
      if (shippingData) {
        await updateShippingMethodMutation({
          variables: {
            updateShippingMethodId: shippingData?.id || '',
            data: priceRangeRules
          }
        });
      } else {
        await addShippingMethodMutation({
          variables: {
            data: priceRangeRules
          }
        });
      }

      handleShowShippingDetail();
      refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };

  const validateDataChange = useCallback(
    (values: any, rules: any) => {
      // const rules = calculateRules(values);
      const priceRangeRules = {
        name: values.name,
        enabled: true,
        delivery_estimation: values.delivery_estimation,
        price_range_rules: rules.map((rule: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { _index, ...rest } = rule;
          return rest;
        })
      };
    },
    [shippingData]
  );

  const validateForm = useCallback(
    (values: any) => {
      const errors: any = {};
      const rules = parseRules(values, rows);

      for (let index = 0; index < rules?.length; index += 1) {
        const rule = rules[index];
        const maxName = `maximum_order_price_${rule._index}`;

        if (rule.maximum_order_price <= rule.minimum_order_price && index !== rules.length - 1) {
          errors[maxName] = 'maximum price must be greater than minimum price';
        }
      }

      if (!values.name) {
        errors.name = 'Name is Required';
      }

      if (!values.delivery_estimation) {
        errors.delivery_estimation = 'Estimated Time is Required';
      }

      // if (rules.length > 0 && !rules[rules.length - 1]?.maximum_order_price) {
      //   errors.add_disabled = true;
      // }

      if (!validatePriceRangeRule(rules)) {
        errors.rates_error = true;
      }

      validateDataChange(values, rules);
      return errors;
    },
    [rows]
  );

  const pinCodesUploadMutation = async (formData: FormData) => {
    const response = await pinCodesUpload(formData);
    return response;
  };

  const handleFileChange = async (file: File[], setFieldValue: any) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file[0]);
      formData.append('file_purpose', 'SHIPPING_PIN_CODES');
      if (file[0].size > 1024 * 1024 * 10) {
        enqueueSnackbar('Oops! File size exceeds the limit (10 MB).', {
          variant: 'error'
        });
        return;
      }
      try {
        const response = await pinCodesUploadMutation(formData);
        setFieldValue('pincode_resource_id', response.data.resource_id);
        setFieldValue('resource_item', file[0].name);
        enqueueSnackbar('Hurray! Pin Codes are uploaded successfully', {
          variant: 'success'
        });
      } catch (error) {
        enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
          variant: 'error'
        });
      }
    }
  };

  const handlePincodeSevice = (setFieldValue: any, value: string) => {
    setFieldValue('pincode_resource_type', value);
  };

  useEffect(() => {
    if (isDirty) {
      dispatch(setShippingDiscardDialog(true));
      dispatch(setShippingBackDropClicked(false));
    } else {
      dispatch(setShippingDiscardDialog(false));
      dispatch(setShippingBackDropClicked(false));
    }
  }, [isDirty]);

  return (
    <div className="w-full sm:w-[570px] overflow-y-scroll scrollbar-hide">
      <div className="sticky top-0 bg-white z-50">
        <div className="flex items-center justify-between font-semibold px-3 py-3.5 sm:p-4">
          <Label size="lg">Add Shipping Method</Label>
          <Button type="submit" size="icon" variant="icon" onClick={handleCancel}>
            <Unicons.UilTimes className="text-[#2F72FF] cursor-pointer" />
          </Button>
        </div>
        <hr />
      </div>

      <Formik
        initialValues={{
          ...rangeRules,
          delivery_estimation: shippingData?.delivery_estimation,
          name: shippingData?.name,
          type: shippingData?.type ?? ShippingType.Price,
          pincode_resource_id: shippingData?.pincode_resource_id,
          pincode_resource_type: shippingData?.pincode_resource_type ?? PincodeType.NonServicable,
          resource_item: shippingData?.resource_item?.file_original_name
        }}
        validateOnChange={true}
        validate={validateForm}
        onSubmit={async (values, { resetForm }) => {
          await handleSave(values);
          resetForm();
        }}
        validateOnBlur={true}
      >
        {({ values, errors, handleSubmit, setFieldValue, validateForm, submitForm, dirty, getFieldProps }) => {
          setIsDirty(dirty);
          return (
            <>
              <div className="p-3 sm:p-4">
                <div className="mb-2">
                  <Label variant="secondary" size="sm">
                    Name
                  </Label>
                  <InputTextField type="name" name="name" placeholder="Standard Shipping" />
                </div>
                <div className="mt-2">
                  <Label variant="secondary" size="sm">
                    Estimated Time
                  </Label>
                  <InputTextField type="text" name="delivery_estimation" placeholder="3-5 Business days" />
                </div>
                <hr className="mt-5 mb-4" />
                <div className="mb-2">
                  <Label size="md" className="text-sm">
                    Type
                  </Label>
                  <RadioGroup
                    {...getFieldProps('type')}
                    defaultValue={ShippingType.Price}
                    className="flex flex-col gap-0 mt-1.5"
                  >
                    <RadioWithLabel
                      label="Based on order price"
                      value={ShippingType.Price}
                      onClick={() => setFieldValue('type', ShippingType.Price)}
                    />
                    <RadioWithLabel
                      label="Based on order weight"
                      value={ShippingType.Weight}
                      onClick={() => setFieldValue('type', ShippingType.Weight)}
                    />
                  </RadioGroup>
                </div>
                <hr className="mt-3 mb-4" />
                <Label size="md" className="mb-6 text-sm">
                  {values.type === ShippingType.Price ? 'Pricing Rates' : 'Weight Rates'}
                </Label>
                <div className="my-4 font-semibold">
                  <div className="border-[1px] rounded-t-lg flex items-center">
                    {pricingRange(values.type)?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={classNames('w-fit text-[#595F74] font-medium p-4', {
                            'mr-[15px] sm:mr-[40px]': index === 0,
                            'mr-[5px]': index === 1,
                            'hidden sm:block': index === 2
                          })}
                        >
                          {item.title}
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex-col">
                    <>
                      <ValidateRules rows={rows} />
                      <form onSubmit={handleSubmit}>
                        {rows?.map((row: any, index: number) => {
                          return (
                            <div key={index} className="flex-col border-t-0 border-[1px] last:rounded-b-lg">
                              <ShippingRuleRow
                                deleteDisabled={rows.length === 1}
                                index={index}
                                ruleIndex={row}
                                key={row}
                                setFieldValue={setFieldValue}
                                handleDelete={(index) => {
                                  onDeleteField(index, setFieldValue, validateForm);
                                }}
                                errors={errors}
                              />
                            </div>
                          );
                        })}
                      </form>
                      {/* {shippingData &&
                      `free delivery above ${values[`maximum_order_price_${rows.length - 1}`]}`} */}
                      <div className="flex justify-end mt-4">
                        <Button
                          variant="outline"
                          size="sm"
                          disabled={
                            // eslint-disable-next-line eqeqeq
                            !!errors?.add_disabled || !!errors?.rates_error || Object.keys(errors).length > 0
                          }
                          onClick={() => {
                            onAddField(values, setFieldValue, validateForm);
                          }}
                        >
                          Add Rate
                        </Button>
                      </div>
                    </>
                    <hr className="my-6" />
                    <div className="my-4 flex flex-col justify-start items-start space-y-1">
                      <div className="flex space-x-1">
                        <Label size="md" className="mb-3 text-sm">
                          Serviceable/Non-serviceable PIN Codes
                        </Label>
                        <Label className="text-[#595F74]" size={'paragraph'}>
                          (optional)
                        </Label>
                      </div>
                      <Card className="w-full flex items-center justify-between p-3">
                        <div className="flex items-center space-x-4">
                          <div className="p-2 w-fit rounded-full border border-muted">
                            {values.resource_item ? (
                              <Unicons.UilFileAlt className="text-[#595F74]" />
                            ) : (
                              <Unicons.UilExport className="text-[#595F74]" />
                            )}
                          </div>
                          <div className="flex flex-col">
                            <Label variant={'default'} size={'paragraph'} className="font-medium">
                              {values.resource_item ? values.resource_item : 'Upload CSV'}
                            </Label>
                            <Label size={'sm'} className="text-[#888D9B]">
                              {values.resource_item ? 'PIN Codes' : 'Upload file from your computer.'}
                            </Label>
                          </div>
                        </div>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleFileChange(acceptedFiles, setFieldValue);
                          }}
                          onDragEnter={() => {
                            setDragEnter(true);
                          }}
                          onDropAccepted={() => {
                            setDragEnter(false);
                            setDragAccepted(true);
                            setTimeout(() => {
                              setDragAccepted(false);
                            }, 500);
                          }}
                          onDropRejected={() => {
                            setDragAccepted(false);
                          }}
                          maxFiles={1}
                          multiple={false}
                          accept={{
                            'text/csv': ['.csv']
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <>
                              {values.resource_item ? (
                                <div className="flex space-x-3">
                                  {shippingData?.resource_item?.download_url ? (
                                    <a
                                      href={
                                        shippingData?.resource_item?.download_url
                                          ? shippingData?.resource_item?.download_url
                                          : ''
                                      }
                                      download={values.resource_item}
                                    >
                                      <Button size="icon" variant="icon">
                                        <Unicons.UilDownloadAlt />
                                      </Button>
                                    </a>
                                  ) : null}
                                  <Button
                                    size="icon"
                                    variant="icon"
                                    onClick={() => {
                                      setFieldValue('pincode_resource_id', null);
                                      setFieldValue('resource_item', null);
                                    }}
                                  >
                                    <Unicons.UilTrashAlt />
                                  </Button>
                                </div>
                              ) : (
                                <Button size={'md'} variant={'outline'} {...getRootProps()}>
                                  {'Upload'}
                                  <input name="pincode" {...getInputProps()} accept=".csv" />
                                </Button>
                              )}
                            </>
                          )}
                        </Dropzone>
                      </Card>
                      <Label className="text-[#595F74] text-xs py-3 font-normal">
                        Please make sure that the file you provided is in the right format.{' '}
                        <Label className="font-medium text-xs underline cursor-pointer">
                          <a
                            href="https://static.flexype.in/assets/checkout/pincodes_example.csv"
                            download={'pincodes_sample'}
                          >
                            Download a sample file.
                          </a>
                        </Label>
                      </Label>
                      <Label size="md" className="mb-2 text-sm">
                        PIN Code Type
                      </Label>
                      <div className="mb-3">
                        <RadioGroup
                          defaultValue={values.pincode_resource_type}
                          value={values.pincode_resource_type}
                          onValueChange={(value: string) => {
                            handlePincodeSevice(setFieldValue, value);
                          }}
                        >
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value={PincodeType.Servicable} id={PincodeType.Servicable} />
                            <Label size={'paragraph'} htmlFor={PincodeType.Servicable} className="text-[#595F74]">
                              Serviceable PIN Codes
                            </Label>
                          </div>
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value={PincodeType.NonServicable} id={PincodeType.NonServicable} />
                            <Label htmlFor={PincodeType.NonServicable} size={'paragraph'} className="text-[#595F74]">
                              Non-serviceable PIN Codes
                            </Label>
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {
                <div className="flex justify-end gap-2 sticky bottom-0 border-t-[1px] border-t-[#E2E2E2] p-3 bg-white w-full transition duration-150 ease-out hover:ease-in z-[10]">
                  <Button variant="outline" size="sm" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    variant="solid"
                    className="ml-3 text-center"
                    disabled={
                      !!errors?.add_disabled ||
                      !!errors?.rates_error ||
                      Object.keys(errors).length > 0 ||
                      !dirty ||
                      isEqual(values, shippingData) ||
                      addingData ||
                      loading
                    }
                    onClick={submitForm}
                    type="submit"
                  >
                    {(shippingData && !loading) || (!shippingData && !addingData) ? (
                      'Save'
                    ) : (
                      <LoadingIcon height={16} className={classNames('h-5 w-5 animate-spin text-white')} />
                    )}
                  </Button>
                </div>
              }
              {shippingBackDropClicked && shippingBackDropClicked ? (
                <ConfirmationDialog
                  showModal={shippingDiscardDialog}
                  setShowModal={setShippingDiscardDialog}
                  onSave={() => {
                    handleShowShippingDetail();
                    dispatch(setShippingBackDropClicked(false));
                    dispatch(setShippingDiscardDialog(false));
                  }}
                  onCancel={() => {
                    if (!shippingDiscardDialog) {
                      dispatch(setShippingBackDropClicked(false));
                    }
                    dispatch(setShippingDiscardDialog(false));
                  }}
                  text={'This action will discard changes. Are you sure you want to continue?'}
                  headerText="Discard changes"
                  confirmButtonText="Discard"
                  confirmActionVariant="destructive"
                  loading={false}
                />
              ) : null}
            </>
          );
        }}
      </Formik>
    </div>
  );
};
