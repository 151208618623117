import Dropzone from 'react-dropzone';
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Button, Card, Label } from '../flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { pinCodesUpload } from '../../network/shipping/shipping';

type props = {
  level: 'ZONE' | 'SHIPPING';
  details: any;
  setResourceId: (data: string | null) => void;
};

export const ShippingPincode: React.FC<props> = ({ level, details, setResourceId }) => {
  const [data, setData] = useState(details);

  const pinCodesUploadMutation = async (formData: FormData) => {
    return await pinCodesUpload(formData);
  };
  const handleFileChange = async (file: File[]) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file[0]);
      formData.append('file_purpose', 'SHIPPING_PIN_CODES');
      if (file[0].size > 1024 * 1024 * 10) {
        enqueueSnackbar('Oops! File size exceeds the limit (10 MB).', {
          variant: 'error'
        });
        return;
      }
      try {
        const response = await pinCodesUploadMutation(formData);
        setResourceId(response.data.resource_id);
        setData({
          ...data,
          file_name: file[0].name
        });
        enqueueSnackbar('Hurray! Pin Codes are uploaded successfully', {
          variant: 'success'
        });
      } catch (error) {
        enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
          variant: 'error'
        });
      }
    }
  };
  return (
    <>
      <div className="mt-4">
        <div className="flex space-x-1">
          <Label size="md" className="text-sm">
            {level === 'ZONE' ? 'Serviceable PIN Codes' : 'Non-serviceable PIN Codes'}
          </Label>
          {level === 'SHIPPING' && (
            <Label className="text-[#595F74]" size={'paragraph'}>
              (optional)
            </Label>
          )}
        </div>
        <Card className="w-full flex items-center justify-between p-3 mt-2 mb-1">
          <div className="flex items-center">
            <div className="p-2 w-fit rounded-full border border-muted">
              {data?.file_name ? (
                <Unicons.UilFileAlt className="text-[#595F74]" />
              ) : (
                <Unicons.UilExport className="text-[#595F74]" />
              )}
            </div>
            <div className="flex flex-col ml-3">
              <Label variant={'default'} size={'paragraph'} className="font-medium">
                {data?.file_name ? data?.file_name : 'Upload CSV'}
              </Label>
              <Label size={'sm'} className="text-[#888D9B]">
                {data?.file_name ? 'PIN Codes' : 'Upload file from your computer.'}
              </Label>
            </div>
          </div>
          <Dropzone
            onDrop={(acceptedFiles) => {
              handleFileChange(acceptedFiles);
            }}
            maxFiles={1}
            multiple={false}
            accept={{
              'text/csv': ['.csv']
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <>
                {data?.file_name ? (
                  <div className="flex space-x-3">
                    {data?.download_url ? (
                      <a
                        href={data?.download_url}
                        download={data?.file_name}
                        onError={(e) => {
                          console.log('Download error', e);
                        }}
                      >
                        <Button size="icon" variant="icon">
                          <Unicons.UilDownloadAlt />
                        </Button>
                      </a>
                    ) : null}
                    <Button
                      size="icon"
                      variant="icon"
                      onClick={() => {
                        setResourceId(null);
                        setData({
                          ...data,
                          file_name: null,
                          download_url: null
                        });
                      }}
                    >
                      <Unicons.UilTrashAlt />
                    </Button>
                  </div>
                ) : (
                  <Button size={'md'} variant={'outline'} {...getRootProps()}>
                    {'Upload'}
                    <input name="pincode" {...getInputProps()} accept=".csv" />
                  </Button>
                )}
              </>
            )}
          </Dropzone>
        </Card>
        <Label className="text-[#595F74] text-xs font-normal">
          Please make sure that the file you provided is in the right format.{' '}
          <Label className="font-medium text-xs underline cursor-pointer">
            <a href="https://static.flexype.in/assets/checkout/pincodes_example.csv" download={'user_phone_sample'}>
              Download a sample file.
            </a>
          </Label>
        </Label>
      </div>
    </>
  );
};
