import React from 'react';

type props = {
  title: string;
  children?: React.ReactNode;
};
export const Title: React.FC<props> = ({ title, children }) => {
  return (
    <div className="flex justify-between items-center py-3">
      <div className="font-bold text-xl py-2">{title}</div>
      {children && <div>{children}</div>}
    </div>
  );
};
