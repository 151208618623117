import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useGetShippingProfileQuery } from '../../graphql';
import { Zones } from '../../components/shipping-v2/zones';
import { Profiles } from '../../components/shipping-v2/profiles';

export const ShippingV2 = () => {
  const { data, loading, refetch } = useGetShippingProfileQuery();

  return (
    <>
      <Routes>
        <Route
          path=""
          element={<Profiles profileData={data?.getShippingProfile || []} loading={loading} profileRefetch={refetch} />}
        />
        <Route path="profile/:profileId" element={<Zones profileData={data?.getShippingProfile || []} />} />
      </Routes>
    </>
  );
};
