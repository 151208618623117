import React, { FC } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useFormikContext } from 'formik';
import { DiscountFields } from '../../../types/coupon-data-types';

type props = {
  activeStep: number;
  selectedPaymentOffer: any | null;
  isEdit: boolean;
};

export const CreateOfferPreview: FC<props> = ({ activeStep, selectedPaymentOffer, isEdit }) => {
  let values = null;
  if (useFormikContext()?.values) {
    values = useFormikContext()?.values;
  } else {
    const val = { ...selectedPaymentOffer };
    val.user_order_requirement = {
      max_order_count: selectedPaymentOffer?.user_order_requirement?.max_order_count || null,
      min_order_count: selectedPaymentOffer?.user_order_requirement?.min_order_count || null,
      type:
        selectedPaymentOffer?.user_order_requirement?.max_order_count === 0 &&
        selectedPaymentOffer?.user_order_requirement?.min_order_count === null
          ? 'first'
          : selectedPaymentOffer?.user_order_requirement?.min_order_count >= 0 &&
              selectedPaymentOffer?.user_order_requirement?.min_order_count !== null
            ? 'order'
            : 'all'
    };
    values = val;
  }

  function generatePurchaseMessage(purchaseRequirement: DiscountFields['purchase_requirement']) {
    const minAmount = purchaseRequirement.min_amount ? purchaseRequirement.min_amount : 0;
    const maxAmount = purchaseRequirement.max_amount ? purchaseRequirement.max_amount : 0;
    const minQuantity = purchaseRequirement.min_quantity ? purchaseRequirement.min_quantity : 0;
    const maxQuantity = purchaseRequirement.max_quantity ? purchaseRequirement.max_quantity : 0;

    if (isEdit) {
      switch (purchaseRequirement.type) {
        case 'NONE':
          return 'Applicable on all carts';
        case 'PURCHASE_AMOUNT':
          return purchaseRequirement.show_max_amount
            ? `For purchases of any item worth ₹${minAmount} to ₹${maxAmount}`
            : `Minimum purchase of ₹${minAmount} of any item`;
        case 'PURCHASE_QUANTITY':
          return purchaseRequirement.show_max_quantity
            ? `For purchases with any ${minQuantity} to ${maxQuantity} item(s)`
            : `Minimum purchase of any ${minQuantity} item(s)`;
        default:
          return '';
      }
    } else {
      switch (purchaseRequirement.type) {
        case 'NONE':
          return 'Applicable on all carts';
        case 'PURCHASE_AMOUNT':
          return maxAmount
            ? `For purchases of any item worth ₹${minAmount} to ₹${maxAmount}`
            : `Minimum purchase of ₹${minAmount} of any item`;
        case 'PURCHASE_QUANTITY':
          return maxQuantity
            ? `For purchases with any ${minQuantity} to ${maxQuantity} item(s)`
            : `Minimum purchase of any ${minQuantity} item(s)`;
        default:
          return '';
      }
    }
  }

  function generateUserOrderMessage(values: any) {
    const type = values.user_order_requirement.type;
    const minOrderCount = values.user_order_requirement.min_order_count || 0;
    const maxOrderCount = values.user_order_requirement.max_order_count || 0;

    switch (type) {
      case 'all':
        return 'For all customers';
      case 'first':
        return 'For first-time customers';
      case 'order':
        if (isEdit) {
          if (!values.user_order_requirement.max_order_count_needed) {
            return `For customers with a minimum of ${minOrderCount} order(s)`;
          } else if (minOrderCount && maxOrderCount) {
            return `For customers with ${minOrderCount} to ${maxOrderCount} order(s)`;
          } else {
            return `For customers with a minimum of ${minOrderCount} order(s)`;
          }
        } else {
          if (!values.user_order_requirement.max_order_count) {
            return `For customers with a minimum of ${minOrderCount} order(s)`;
          } else if (minOrderCount && maxOrderCount) {
            return `For customers with ${minOrderCount} to ${maxOrderCount} order(s)`;
          } else {
            return `For customers with a minimum of ${minOrderCount} order(s)`;
          }
        }
      default:
        return '';
    }
  }

  function renderUsageLimit(label: string, value: number | string, suffix = '') {
    return (
      <li className="ml-4">
        {value !== null && value !== undefined && value !== '' && value !== 0
          ? `${label} ${value} ${suffix}`
          : `${label} ${suffix}`}
      </li>
    );
  }

  return (
    <div className="h-fit resize-none border rounded-[14px] w-full sm:w-[20rem]">
      {values && (
        <>
          <div className="font-semibold p-2">{values?.code ? values?.code.toUpperCase() : 'No discount code yet'}</div>
          <hr />
          <div className="font-semibold p-2">Discount Properties</div>
          <div className="text-[#888D9B] pt-2 px-2">SETUP</div>
          <ul className="list-disc p-2">
            {values?.apply_discount_to === 'ALL_PAYMENT_MODE' && values?.discount_amount ? (
              <li className="ml-4 ">
                {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                {values?.discount_amount}
                {values?.discount_type === 'PERCENTAGE' && '%'} off{' '}
                {values?.discount_max_cap && values?.discount_max_cap > 0 && values?.discount_type === 'PERCENTAGE'
                  ? `upto ₹${values?.discount_max_cap}`
                  : ''}
                {' on all payment offers'}
              </li>
            ) : (
              <>
                {values?.payment_offers?.ALL && values?.payment_offers?.ALL.active ? (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.ALL?.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.ALL?.discount_max_cap &&
                    values?.payment_offers?.ALL?.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.ALL?.discount_max_cap}`
                      : ''}
                    {' on all payment offers'}
                  </li>
                ) : (
                  ''
                )}
                {values?.payment_offers?.UPI && values?.payment_offers?.UPI.active && (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.UPI.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.UPI.discount_max_cap &&
                    values?.payment_offers?.UPI.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.UPI.discount_max_cap}`
                      : ''}
                    {' on UPI'}
                  </li>
                )}
                {values?.payment_offers?.EMI && values?.payment_offers?.EMI.active && (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.EMI.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.EMI.discount_max_cap &&
                    values?.payment_offers?.EMI.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.EMI.discount_max_cap}`
                      : ''}
                    {' on EMI'}
                  </li>
                )}
                {values?.payment_offers?.CARD && values?.payment_offers?.CARD.active && (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.CARD.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.CARD.discount_max_cap &&
                    values?.payment_offers?.CARD.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.CARD.discount_max_cap}`
                      : ''}
                    {' on Credit/Debit cards'}
                  </li>
                )}
                {values?.payment_offers?.WALLET && values?.payment_offers?.WALLET.active && (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.WALLET.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.WALLET.discount_max_cap &&
                    values?.payment_offers?.WALLET.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.WALLET.discount_max_cap}`
                      : ''}
                    {' on Wallets'}
                  </li>
                )}
                {values?.payment_offers?.NET_BANKING && values?.payment_offers?.NET_BANKING.active && (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.NET_BANKING.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.NET_BANKING.discount_max_cap &&
                    values?.payment_offers?.NET_BANKING.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.NET_BANKING.discount_max_cap}`
                      : ''}
                    {' on Net Banking'}
                  </li>
                )}
                {values?.payment_offers?.PAY_LATER && values?.payment_offers?.PAY_LATER.active && (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.PAY_LATER.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.PAY_LATER.discount_max_cap &&
                    values?.payment_offers?.PAY_LATER.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.PAY_LATER.discount_max_cap}`
                      : ''}
                    {' on Pay Later'}
                  </li>
                )}

                {values?.payment_offers?.upi && values?.payment_offers?.upi.active ? (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.upi.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.upi?.show_discount_max_cap &&
                    values?.payment_offers?.upi.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.upi.discount_max_cap}`
                      : ''}
                    {' on UPI'}
                  </li>
                ) : (
                  ''
                )}
                {values?.payment_offers?.emi && values?.payment_offers?.emi.active ? (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.emi.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.emi?.show_discount_max_cap &&
                    values?.payment_offers?.emi.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.emi.discount_max_cap}`
                      : ''}
                    {' on EMI'}
                  </li>
                ) : (
                  ''
                )}
                {values?.payment_offers?.card && values?.payment_offers?.card.active ? (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.card.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.card?.show_discount_max_cap &&
                    values?.payment_offers?.card.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.card.discount_max_cap}`
                      : ''}
                    {' on Credit/Debit cards'}
                  </li>
                ) : (
                  ''
                )}
                {values?.payment_offers?.wallet && values?.payment_offers?.wallet.active ? (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.wallet.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.wallet?.show_discount_max_cap &&
                    values?.payment_offers?.wallet.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.wallet.discount_max_cap}`
                      : ''}
                    {' on Wallets'}
                  </li>
                ) : (
                  ''
                )}
                {values?.payment_offers?.pay_later && values?.payment_offers?.pay_later.active ? (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.pay_later.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.pay_later?.show_discount_max_cap &&
                    values?.payment_offers?.pay_later.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.pay_later.discount_max_cap}`
                      : ''}
                    {' on Pay Later'}
                  </li>
                ) : (
                  ''
                )}
                {values?.payment_offers?.net_banking && values?.payment_offers?.net_banking.active ? (
                  <li className=" ml-4 ">
                    {values?.discount_type === 'PERCENTAGE' ? '' : '₹'}
                    {values?.payment_offers?.net_banking.amount || 0}
                    {values?.discount_type === 'PERCENTAGE' ? '%' : ''} off{' '}
                    {values?.payment_offers?.net_banking?.show_discount_max_cap &&
                    values?.payment_offers?.net_banking.discount_max_cap > 0 &&
                    values?.discount_type === 'PERCENTAGE'
                      ? `upto ₹${values?.payment_offers?.net_banking.discount_max_cap}`
                      : ''}
                    {' on Net Banking'}
                  </li>
                ) : (
                  ''
                )}
              </>
            )}
            {/* Don't remove */}
            {/* <li className=" ml-4 ">
              {!values?.discoverable
                ? 'Offer will not be shown to any customer'
                : 'Offer will be shown to eligible customers'}
            </li> */}
          </ul>
          {activeStep >= 1 && (
            <>
              <div className="text-[#888D9B]  pt-2 px-2">CONDITION</div>

              <ul className=" list-disc p-2">
                <li className=" ml-4 ">{generatePurchaseMessage(values.purchase_requirement)}</li>
              </ul>

              {activeStep >= 2 && (
                <>
                  <div className="text-[#888D9B]  pt-2 px-2">ELIGIBILITY</div>
                  <ul className=" list-disc p-2">
                    <li className=" ml-4 ">{generateUserOrderMessage(values)}</li>
                  </ul>
                </>
              )}
              {activeStep >= 3 && (
                <>
                  <div className="text-[#888D9B] pt-2 px-2">LIMITS</div>
                  <ul className=" list-disc p-2">
                    {isEdit &&
                      values.show_total_usage_limit &&
                      renderUsageLimit('Limit to', values.total_usage_limit, 'use(s)')}
                    {isEdit &&
                      values.show_per_customer_usage_limit &&
                      renderUsageLimit('Limit to', values.per_customer_usage_limit, 'use(s) per customer')}
                    {isEdit &&
                      !values.show_total_usage_limit &&
                      !values.show_per_customer_usage_limit &&
                      renderUsageLimit('No usage limit', '')}

                    {!isEdit &&
                      values.total_usage_limit &&
                      renderUsageLimit('Limit to', values.total_usage_limit, 'use(s)')}
                    {!isEdit &&
                      values.per_customer_usage_limit &&
                      renderUsageLimit('Limit to', values.per_customer_usage_limit, 'use(s) per customer')}
                    {!isEdit &&
                      !values.total_usage_limit &&
                      !values.per_customer_usage_limit &&
                      renderUsageLimit('No usage limit', '')}
                  </ul>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
