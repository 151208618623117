import { Formik } from 'formik';
import classNames from 'classnames';
import { enqueueSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { ShippingDrawerRules } from './shipping-drawer-rules';
import * as Unicons from '@iconscout/react-unicons';
import { ShippingPincode } from './shipping-pincode';
import { Drawer, DrawerContent } from '../flexyui/Drawer';
import { Button, Input, Label, RadioGroup, Separator } from '../flexyui';
import { ShippingSchema } from '../../utils/validation-schemas/shipping';
import { RadioWithLabel } from '../shared/radio-with-label/radio-with-label';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';
import {
  CreatedByType,
  ShippingZoneType,
  useAddShippingRateMutation,
  useUpdateShippingRateMutation
} from '../../graphql';

interface PriceConditionRule {
  minimum_value: number;
  maximum_value: number | null;
  delivery_price: number;
  cod_charges: number | null;
  cod_accepted: boolean;
}

export interface ShippingFields {
  title: string;
  delivery_estimation: string;
  enabled: boolean;
  type: any;
  price_condition_rules: PriceConditionRule[];
}

type props = {
  open: boolean;
  setOpen: (data: any) => void;
  shippingRateData: any;
  refetch: () => void;
};

export const ShippingDrawer: React.FC<props> = ({ open, setOpen, shippingRateData, refetch }) => {
  const [addRate] = useAddShippingRateMutation();
  const [updateRate] = useUpdateShippingRateMutation();
  const [resourceId, setResourceId] = useState<string | null>(null);

  const initialValues = useMemo(
    () => ({
      title: shippingRateData?.data?.title || '',
      delivery_estimation: shippingRateData?.data?.delivery_estimation || '',
      enabled: shippingRateData?.data?.enabled || true,
      type: shippingRateData?.data?.type || 'PRICE',
      price_condition_rules: shippingRateData?.data?.price_condition_rules || [
        {
          minimum_value: 0,
          maximum_value: null,
          delivery_price: 0,
          cod_charges: null,
          cod_accepted: false
        }
      ]
    }),
    [shippingRateData]
  );

  const handleSave = async (values: any) => {
    const updatedPriceConditionRules = values.price_condition_rules.map((rule: any) => ({
      ...rule,
      maximum_value: rule.maximum_value === '' ? null : rule.maximum_value,
      cod_charges: rule.cod_accepted === false ? null : rule.cod_charges
    }));
    const updatedValues = {
      ...values,
      ...(resourceId && { resource_id: resourceId }),
      price_condition_rules: updatedPriceConditionRules
    };

    try {
      if (shippingRateData.data) {
        await updateRate({
          variables: {
            shippingId: shippingRateData.zoneId,
            shippingRateId: shippingRateData.data.id,
            configs: updatedValues
          }
        });
      } else {
        await addRate({
          variables: {
            shippingId: shippingRateData.zoneId,
            configs: updatedValues
          }
        });
      }
      enqueueSnackbar(`Rate has been ${shippingRateData ? 'updated' : 'created'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setOpen(false);
    }
  };

  const handleTypeChange = (type: string, setFieldValue: (field: string, value: any) => void) => {
    setFieldValue('type', type);
    setFieldValue('price_condition_rules', [
      {
        minimum_value: 0,
        maximum_value: null,
        delivery_price: 0,
        cod_charges: null,
        cod_accepted: false
      }
    ]);
  };

  const isShopifyCreated = shippingRateData?.data?.created_by === CreatedByType.Shopify;

  return (
    <Drawer direction="right" open={open} onOpenChange={(value: boolean) => setOpen(value)}>
      <DrawerContent>
        <Formik<ShippingFields>
          enableReinitialize
          initialValues={initialValues}
          validateOnChange={true}
          validateOnMount={true}
          validateOnBlur={true}
          validationSchema={ShippingSchema}
          onSubmit={handleSave}
        >
          {({ errors, touched, setFieldValue, getFieldProps, submitForm, isSubmitting, resetForm }) => {
            return (
              <div className="w-full relative sm:w-[570px] h-full overflow-y-scroll scrollbar-hide">
                <div className="sticky top-0 bg-white z-50">
                  <div className="flex items-center justify-between font-semibold px-3 py-3.5 sm:p-4">
                    <Label size="lg">{shippingRateData.data ? 'Edit' : 'Add'} Shipping Rate</Label>
                    <Button type="submit" size="icon" variant="icon" onClick={() => setOpen(false)}>
                      <Unicons.UilTimes className="text-[#2F72FF] cursor-pointer" />
                    </Button>
                  </div>
                  <hr />
                </div>
                <div className="p-3 sm:p-4 mb-20">
                  <div className="mb-2">
                    <Label size="paragraph">Name</Label>
                    <Input
                      {...getFieldProps('title')}
                      disabled={isShopifyCreated}
                      error={touched.title && !!errors.title}
                      errorMessage={errors.title}
                      placeholder="Standard Shipping"
                      className="mt-1"
                    />
                  </div>
                  <div className="mb-2">
                    <Label size="paragraph">Estimated Time</Label>
                    <Input
                      {...getFieldProps('delivery_estimation')}
                      error={touched.delivery_estimation && !!errors.delivery_estimation}
                      errorMessage={errors.delivery_estimation}
                      placeholder="3-5 Business days"
                      className="mt-1"
                    />
                  </div>
                  <Separator />
                  {isShopifyCreated ? (
                    <div className="flex flex-col">
                      <Label size="md" className="text-sm">
                        Type
                      </Label>
                      <Label className="text-[#888D9B]" size="sm">
                        Based on order {shippingRateData?.data?.type?.toLowerCase()}
                      </Label>
                    </div>
                  ) : (
                    <>
                      <Label size="md" className="text-sm">
                        Type
                      </Label>
                      <RadioGroup
                        {...getFieldProps('type')}
                        defaultValue="PRICE"
                        className="flex flex-col gap-0 mt-1.5"
                      >
                        <RadioWithLabel
                          label="Based on order price"
                          value="PRICE"
                          onClick={() => handleTypeChange('PRICE', setFieldValue)}
                        />
                        <RadioWithLabel
                          label="Based on order weight"
                          value="WEIGHT"
                          onClick={() => handleTypeChange('WEIGHT', setFieldValue)}
                        />
                      </RadioGroup>
                    </>
                  )}

                  <Separator />
                  <Label size="md" className="text-sm">
                    Pricing Conditions
                  </Label>

                  <ShippingDrawerRules isShopifyCreated={isShopifyCreated} />
                  {shippingRateData.zoneType === ShippingZoneType.StateCountry && (
                    <>
                      <Separator />
                      <ShippingPincode
                        level="SHIPPING"
                        details={shippingRateData?.data}
                        setResourceId={setResourceId}
                      />
                    </>
                  )}
                </div>
                <div className="flex justify-end gap-2 fixed bottom-0 border-t-[1px] border-t-[#E2E2E2] p-3 bg-white w-full transition duration-150 ease-out hover:ease-in z-[10]">
                  <Button variant="outline" size="sm" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    variant="solid"
                    className="ml-3 text-center"
                    onClick={async () => {
                      await submitForm();
                      resetForm();
                    }}
                    disabled={Object.keys(errors)?.length > 0 || isSubmitting}
                  >
                    {isSubmitting ? (
                      <LoadingIcon height={16} className={classNames('h-5 w-5 animate-spin text-white')} />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </div>
            );
          }}
        </Formik>
      </DrawerContent>
    </Drawer>
  );
};
