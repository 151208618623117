import React, { useState } from 'react';
import { Button } from '../Button';
import { Label } from '../Label';
import { Card } from '../Card';
import Calculations from './calculations';

type Props = {
  children: React.ReactNode;
  heading: string;
  description: any;
  calculation?: boolean;
  calculationVariable?: string;
  calculationValue?: string;
  simple?: boolean;
  side?: 'left' | 'right' | 'bottom' | 'top';
  align?: 'start' | 'end' | 'center';
  className?: string;
};

export const ButtonWithInfo = ({
  children,
  heading,
  description,
  calculation,
  calculationVariable,
  calculationValue,
  simple = false,
  side = 'bottom',
  align = 'start',
  className = ''
}: Props) => {
  const [hover, setHover] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const getTooltipPositionClasses = () => {
    let positionClasses = '';

    // Calculate position based on 'side'
    switch (side) {
      case 'left':
        positionClasses += 'right-full';
        break;
      case 'right':
        positionClasses += 'left-full';
        break;
      case 'bottom':
        positionClasses += 'top-full';
        break;
      case 'top':
        positionClasses += 'bottom-full';
        break;
      default:
        positionClasses += 'top-full';
        break;
    }

    // Calculate alignment based on 'align'
    switch (align) {
      case 'start':
        if (side === 'left' || side === 'right') {
          positionClasses += 'top-0 ';
        } else {
          positionClasses += 'left-0 ';
        }
        break;
      case 'end':
        if (side === 'left' || side === 'right') {
          positionClasses += 'bottom-0 ';
        } else {
          positionClasses += 'right-0 ';
        }
        break;
      case 'center':
      default:
        if (side === 'left' || side === 'right') {
          positionClasses += 'top-1/2 transform -translate-y-1/2 ';
        } else {
          positionClasses += 'left-1/2 transform -translate-x-1/2 ';
        }
        break;
    }

    return positionClasses;
  };

  const tooltipStyles: any = {
    container: {
      position: 'relative',
      display: 'inline-block'
    },
    tooltip: {
      position: 'absolute',
      zIndex: 10,
      display: 'inline-block',
      padding: '5px 15px',
      fontSize: '13px',
      fontWeight: 500,
      borderRadius: '0.375rem',
      opacity: hover ? 1 : 0,
      transition: 'opacity 0.3s',
      backgroundColor: '#2d3748',
      color: '#fff'
    },
    top: {
      bottom: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      marginBottom: '0.5rem'
    },
    right: {
      top: '50%',
      left: '100%',
      transform: 'translateY(-50%)',
      marginLeft: '0.5rem'
    },
    bottom: {
      top: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
      marginTop: '0.5rem'
    },
    left: {
      top: '50%',
      right: '100%',
      transform: 'translateY(-50%)',
      marginRight: '0.5rem'
    },
    arrow: {
      width: 0,
      height: 0,
      borderStyle: 'solid'
    },
    darkArrow: (side: 'left' | 'right' | 'bottom' | 'top') => {
      switch (side) {
        case 'top':
          return {
            borderColor: 'transparent transparent #2d3748 transparent',
            borderWidth: '0 0.5rem 0.5rem 0.5rem',
            position: 'absolute',
            bottom: '-6px',
            left: '50%',
            transform: 'translateX(-50%) rotate(-180deg)'
          };
        case 'right':
          return {
            borderColor: '#2d3748 transparent transparent transparent',
            borderWidth: '0.5rem 0.5rem 0 0.5rem',
            position: 'absolute',
            left: '-0.6rem',
            top: '50%',
            transform: 'translateY(-50%) rotate(90deg)'
          };
        case 'bottom':
          return {
            borderColor: '#2d3748 transparent transparent transparent',
            borderWidth: '0.5rem 0.5rem 0 0.5rem',
            position: 'absolute',
            top: '-6px',
            left: '50%',
            transform: 'translateX(-50%) rotate(-180deg)'
          };
        case 'left':
          return {
            borderColor: '#2d3748 transparent transparent transparent',
            borderWidth: '0.5rem 0.5rem 0 0.5rem',
            position: 'absolute',
            right: '-0.6rem',
            top: '50%',
            transform: 'translateY(-50%) rotate(-90deg)'
          };
        default:
          return {};
      }
    }
  };

  return (
    <>
      {simple ? (
        <div
          className="hidden sm:inline-block"
          style={tooltipStyles.container}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {children}
          {hover && (
            <div
              style={{
                ...tooltipStyles.tooltip,
                ...tooltipStyles[side]
              }}
              className={className}
            >
              {heading || description}
              <div
                style={{
                  ...tooltipStyles.arrow,
                  ...tooltipStyles.darkArrow(side)
                }}
              ></div>
            </div>
          )}
        </div>
      ) : (
        <div className="relative hidden sm:block" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div className="flex items-center">
            <Button variant={'info'} className="w-fit h-fit">
              {children}
            </Button>
          </div>
          <div
            className={`
              absolute p-2 z-[1500] bg-transparent transition-all duration-150 ease-in-out
              ${getTooltipPositionClasses()}
              ${hover ? 'opacity-100 transform scale-100 visible' : 'opacity-0 transform scale-95 invisible'} 
              ${className}
            `}
          >
            <Card className="relative h-fit min-w-[400px] max-w-[400px] space-y-2 p-4 rounded-2xl shadow-lg flex flex-col">
              <Label size={'paragraph'} className="font-semibold">
                {heading}
              </Label>
              <Label size={'default'} className="leading-4 !font-normal">
                {description}
              </Label>
              {calculation ? (
                <Card className="p-3 bg-[#f4f4f4] leading-4 shadow-none">
                  <Calculations value={calculationValue || ''} variable={calculationVariable || ''} />
                </Card>
              ) : null}
            </Card>
          </div>
        </div>
      )}
    </>
  );
};
