import { Formik } from 'formik';
import { cloneDeep } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { DiscountDialog } from '../discount-dialog';
import React, { FC, useMemo, useState } from 'react';
import { OfferFields } from '../../../types/payment-offers-data-types';
import { DiscountPaymentSetup } from '../../shared/discount/discount-setup/discount-payment-setup';
import { paymentOfferDataHandler } from '../../../utils/payment-offers/payment-offer-data-handler';
import { DiscountPaymentSchema } from '../../../utils/validation-schemas/discount/discount-payment';
import { DiscountUsageLimit } from '../../shared/discount/discount-usage-limit/discount-usage-limit';
import { getPaymentOfferInitialValues } from '../../../utils/payment-offers/payment-offer-initial-values';
import { CouponType, useCreatePaymentOfferMutation, useUpdatePaymentOfferMutation } from '../../../graphql';
import { DiscountUserEligibility } from '../../shared/discount/discount-eligibility/discount-user-eligibility';
import { DiscountRequirementPayment } from '../../shared/discount/discount-condition/discount-requirement-payment';

type props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  offerDetails: any;
  setOfferDetails: (value: any) => void;
  refetch: () => void;
};

export const DiscountPaymentDialog: FC<props> = ({ open, setOpen, offerDetails, setOfferDetails, refetch }) => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const [createPaymentOffer] = useCreatePaymentOfferMutation();
  const [updatePaymentOffer] = useUpdatePaymentOfferMutation();

  const handleSave = async (values: any) => {
    const newValues = cloneDeep(values);
    const body = paymentOfferDataHandler(newValues);
    try {
      if (offerDetails?.id) {
        await updatePaymentOffer({
          variables: {
            couponId: offerDetails.id,
            data: body
          }
        });
      } else {
        await createPaymentOffer({
          variables: {
            data: body
          }
        });
      }
      setOpen(false);
      setOfferDetails(null);
      enqueueSnackbar(`Hurray! Payment Offer ${offerDetails?.id ? 'updated' : 'created'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e) {
      setOpen(true);
      body.purchase_requirement.products = offerDetails?.purchase_requirement?.products || [];
      setOfferDetails(body);

      enqueueSnackbar(`Oops! Payment Offer ${offerDetails ? 'update' : 'creation'} failed!`, {
        variant: 'error'
      });
    } finally {
      setActiveStep(0);
    }
  };

  const initialValues = useMemo(() => getPaymentOfferInitialValues(offerDetails), [offerDetails]);

  return (
    <Formik<OfferFields>
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={true}
      validateOnMount={true}
      validateOnBlur={true}
      validationSchema={DiscountPaymentSchema}
      onSubmit={handleSave}
    >
      {({ errors, submitForm, resetForm, isSubmitting }) => {
        return (
          <>
            <DiscountDialog
              open={open}
              setOpen={setOpen}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              type={CouponType.Payment}
              errors={errors}
              discountDetails={offerDetails}
              setDiscountDetails={setOfferDetails}
              isSubmitting={isSubmitting}
              resetForm={resetForm}
              submitForm={submitForm}
              content={
                <>
                  {activeStep === 0 && <DiscountPaymentSetup />}
                  {activeStep === 1 && <DiscountRequirementPayment />}
                  {activeStep === 2 && <DiscountUserEligibility isPaymentOffer={true} />}
                  {activeStep === 3 && <DiscountUsageLimit isPaymentOffer={true} showCombine={false} />}
                </>
              }
            />
          </>
        );
      }}
    </Formik>
  );
};
