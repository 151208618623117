import * as Yup from 'yup';
import { UserScope } from '../../../constants/coupons';
import { ApplicationTarget, DiscountType } from '../../../graphql';
import { DiscountSchema, PurchaseRequirementSchema } from './index';
import { ErrorMessages, requiredError } from '../../../constants/error-messages';

export const DiscountAmountSchema = DiscountSchema.shape({
  discount_type: Yup.string().default(DiscountType.Percentage).required(),
  discount_amount: Yup.number()
    .required(requiredError('Discount value'))
    .min(0, ErrorMessages.NEGATIVE_ERROR)
    .when('discount_type', {
      is: DiscountType.Percentage,
      then: (schema) => schema.max(100, ErrorMessages.DISCOUNT_PERCENTAGE_MAX_ERROR)
    }),
  discount_max_cap: Yup.number().when(['discount_type', 'show_discount_max_cap'], {
    is: (discountType: DiscountType, showDiscountMaxCap: boolean) =>
      discountType === DiscountType.Percentage && showDiscountMaxCap,
    then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Maximum cap')),
    otherwise: (schema) => schema.nullable()
  }),
  show_discount_max_cap: Yup.boolean().default(false),
  application: Yup.object().shape({
    type: Yup.string().default(ApplicationTarget.Universal).required(),
    products: Yup.array()
      .default([])
      .when('type', {
        is: ApplicationTarget.Products,
        then: (schema) => schema.min(1, ErrorMessages.MIN_PRODUCT_ERROR)
      }),
    collections: Yup.array()
      .default([])
      .when('type', {
        is: ApplicationTarget.Collections,
        then: (schema) => schema.min(1, ErrorMessages.MIN_COLLECTION_ERROR)
      })
  }),
  purchase_requirement: PurchaseRequirementSchema.shape({
    products: Yup.array()
      .default([])
      .when(['type', 'productType'], {
        is: (type: string, productType: string) => type !== 'NONE' && productType === 'PURCHASE_PRODUCTS',
        then: (schema) => schema.min(1, ErrorMessages.MIN_PRODUCT_ERROR)
      }),
    collections: Yup.array()
      .default([])
      .when(['type', 'productType'], {
        is: (type: string, productType: string) => type !== 'NONE' && productType === 'PURCHASE_COLLECTIONS',
        then: (schema) => schema.min(1, ErrorMessages.MIN_COLLECTION_ERROR)
      })
  }),
  user_specific_metadata: Yup.object().shape({
    user_scope: Yup.string().default(UserScope.ALL_USERS).required(),
    resource_id: Yup.string().when('user_scope', {
      is: UserScope.SPECIFIC_USERS,
      then: (schema) => schema.required(requiredError('CSV file')),
      otherwise: (schema) => schema.nullable()
    })
  })
});
