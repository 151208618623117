import { DiscountUserSpecific } from './discount-user-specific';
import { DiscountUserEligibility } from './discount-user-eligibility';

export const DiscountEligibility = () => {
  return (
    <>
      <DiscountUserSpecific />
      <hr />
      <DiscountUserEligibility />
    </>
  );
};
