import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  Switch
} from '../../../../flexyui';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { enqueueSnackbar } from 'notistack';
import { MetaPixelSchema } from '../../../../../utils/validation-schemas/integrations';
import { ReactComponent as LoadingIcon } from '../../../../../assets/images/loading.svg';
import { Pixel, useAddMetaAnalyticsMutation, useUpdateMetaAnalyticsMutation } from '../../../../../graphql';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  pixelData: Pixel | null;
  setPixelData: (value: Pixel | null) => void;
  handleMetaPixelStatus: () => void;
  refetch: () => void;
};

export const MetaPixelDialog: React.FC<Props> = ({
  open,
  setOpen,
  pixelData,
  setPixelData,
  handleMetaPixelStatus,
  refetch
}) => {
  const [addPixel] = useAddMetaAnalyticsMutation();
  const [updatePixel] = useUpdateMetaAnalyticsMutation();

  const handleSave = async (values: any) => {
    try {
      if (pixelData) {
        await updatePixel({
          variables: {
            configs: values,
            updateMetaAnalyticsId: pixelData.id
          }
        });
      } else {
        handleMetaPixelStatus();
        await addPixel({
          variables: {
            configs: values
          }
        });
      }
      enqueueSnackbar(`Pixel has been ${pixelData ? 'updated' : 'added'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setOpen(false);
      setPixelData(null);
    }
  };

  const initialValues = useMemo(() => {
    console.log('pixelData', pixelData);
    return {
      enabled: pixelData?.enabled ?? true,
      pixel_id: pixelData?.pixel_id || '',
      conversion_api_token: pixelData?.conversion_api_token || '',
      conversion_api_enabled: pixelData?.conversion_api_enabled ?? true
    };
  }, [pixelData]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={true}
      validateOnMount={true}
      validateOnBlur={true}
      validationSchema={MetaPixelSchema}
      onSubmit={handleSave}
    >
      {({ values, errors, touched, setFieldValue, getFieldProps, submitForm, resetForm, isSubmitting }) => {
        console.log('values', values);
        return (
          <Dialog open={open} onOpenChange={(value) => setOpen(value)}>
            <DialogContent className="!gap-0" size="sm" close={true}>
              <div>
                <DialogHeader>
                  <DialogTitle>
                    <p>{pixelData ? 'Edit' : 'Add'} Pixel Data</p>
                  </DialogTitle>
                  <DialogDescription className="!p-0 !m-0">
                    <div className="p-4">
                      <div>
                        <Label size="paragraph">Pixel ID</Label>
                        <Input
                          {...getFieldProps('pixel_id')}
                          error={touched.pixel_id && !!errors.pixel_id}
                          errorMessage={errors.pixel_id}
                          className="text-[#121b38] mt-1"
                        />
                      </div>
                      <div className="mt-3">
                        <Label size="paragraph">Conversions API token</Label>
                        <Input
                          {...getFieldProps('conversion_api_token')}
                          error={touched.conversion_api_token && !!errors.conversion_api_token}
                          errorMessage={errors.conversion_api_token}
                          className="text-[#121b38]"
                        />
                      </div>

                      <div className="mt-3 flex justify-between items-center">
                        <Label size="paragraph">Conversion API Status</Label>
                        <Switch
                          checked={values.conversion_api_enabled}
                          className="pointer-events-auto mr-1"
                          onCheckedChange={(value) => setFieldValue('conversion_api_enabled', value)}
                        />
                      </div>
                    </div>
                  </DialogDescription>
                </DialogHeader>
              </div>
              <DialogFooter>
                <Button
                  variant={'outline'}
                  size={'md'}
                  onClick={() => {
                    setPixelData(null);
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant={'default'}
                  size={'md'}
                  type="submit"
                  disabled={Object.keys(errors).length > 0 || isSubmitting}
                  onClick={async () => {
                    await submitForm();
                    resetForm();
                  }}
                >
                  {isSubmitting ? (
                    <LoadingIcon height={20} className={'animate-spin text-white'} />
                  ) : pixelData ? (
                    'Save'
                  ) : (
                    'Add'
                  )}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        );
      }}
    </Formik>
  );
};
