import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { Input, RadioGroup } from '../../../flexyui';
import { DiscountFields } from '../../../../types/coupon-data-types';
import { InfoCardUserEligibility } from '../../../../constants/coupons';
import { RadioWithLabel } from '../../radio-with-label/radio-with-label';
import { OfferFields } from '../../../../types/payment-offers-data-types';
import { DiscountInfoCard } from '../discount-info-card/discount-info-card';
import { CheckboxWithLabel } from '../../checkbox-with-label/checkbox-with-label';

type props = {
  isPaymentOffer?: boolean;
};

export const DiscountUserEligibility: FC<props> = ({ isPaymentOffer = false }) => {
  let formikContext;

  if (isPaymentOffer) {
    formikContext = useFormikContext<DiscountFields>();
  } else {
    formikContext = useFormikContext<OfferFields>();
  }

  const { values, touched, errors, getFieldProps, setFieldValue } = formikContext;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
      <DiscountInfoCard data={InfoCardUserEligibility[0]} />
      <div>
        <div className="font-semibold text-base mb-4 block sm:hidden">Are there any user eligibility criteria?</div>
        <RadioGroup {...getFieldProps('user_order_requirement.type')} defaultValue="all">
          <RadioWithLabel
            label="All orders"
            value="all"
            onClick={() => setFieldValue('user_order_requirement.type', 'all')}
          />
          <RadioWithLabel
            label="First order only"
            value="first"
            onClick={() => setFieldValue('user_order_requirement.type', 'first')}
          />
          <RadioWithLabel
            label="Total order count"
            value="order"
            onClick={() => setFieldValue('user_order_requirement.type', 'order')}
          />
        </RadioGroup>
        {values?.user_order_requirement?.type === 'order' && (
          <>
            <div className="flex gap-4 mt-8">
              <div className={'w-[150px]'}>
                <div className="font-medium mb-1">Minimum order count</div>
                <Input
                  {...getFieldProps('user_order_requirement.min_order_count')}
                  type="number"
                  error={
                    touched.user_order_requirement?.min_order_count && !!errors.user_order_requirement?.min_order_count
                  }
                  errorMessage={errors.user_order_requirement?.min_order_count}
                />
              </div>
              {values?.user_order_requirement?.max_order_count_needed && (
                <div className={'w-[150px]'}>
                  <div className="font-medium mb-1">Maximum order count</div>
                  <Input
                    {...getFieldProps('user_order_requirement.max_order_count')}
                    type="number"
                    error={
                      touched.user_order_requirement?.max_order_count &&
                      !!errors.user_order_requirement?.max_order_count
                    }
                    errorMessage={errors.user_order_requirement?.max_order_count}
                  />
                </div>
              )}
            </div>
            <CheckboxWithLabel
              label="Maximum order count cap"
              value="user_order_requirement.max_order_count_needed"
              checked={values.user_order_requirement.max_order_count_needed}
              onClick={() =>
                setFieldValue(
                  'user_order_requirement.max_order_count_needed',
                  !values.user_order_requirement.max_order_count_needed
                )
              }
            />
          </>
        )}
      </div>
    </div>
  );
};
