import * as Yup from 'yup';
import { DiscountSchema, PurchaseRequirementSchema } from './index';
import { DiscountType, PurchaseActivityType } from '../../../graphql';
import { ErrorMessages, requiredError } from '../../../constants/error-messages';

export const DiscountPurchaseDriven = DiscountSchema.shape({
  discount_type: Yup.string().default(DiscountType.Percentage).required(),
  discount_amount: Yup.number()
    .required(requiredError('Discount value'))
    .min(0, ErrorMessages.NEGATIVE_ERROR)
    .when('discount_type', {
      is: DiscountType.Percentage,
      then: (schema) => schema.max(100, ErrorMessages.DISCOUNT_PERCENTAGE_MAX_ERROR)
    }),
  discount_max_cap: Yup.number().when(['discount_type', 'show_discount_max_cap'], {
    is: (discountType: DiscountType, showDiscountMaxCap: boolean) =>
      discountType === DiscountType.Percentage && showDiscountMaxCap,
    then: (schema) => schema.moreThan(0, ErrorMessages.NEGATIVE_ERROR).required(requiredError('Maximum cap')),
    otherwise: (schema) => schema.nullable()
  }),
  show_discount_max_cap: Yup.boolean().default(false),
  purchase_activity: Yup.object().shape({
    type: Yup.string().default(PurchaseActivityType.CouponCode).required(),
    min_amount: Yup.number().when('type', {
      is: PurchaseActivityType.OrderAmount,
      then: (schema) => schema.required(requiredError('Amount')).min(0, ErrorMessages.NEGATIVE_ERROR),
      otherwise: (schema) => schema.nullable()
    }),
    min_quantity: Yup.number()
      .integer(ErrorMessages.WHOLE_NO_ERROR)
      .when('type', {
        is: PurchaseActivityType.OrderCount,
        then: (schema) => schema.required(requiredError('Quantity')).min(0, ErrorMessages.NEGATIVE_ERROR),
        otherwise: (schema) => schema.nullable()
      }),
    selected_coupon_id: Yup.string()
      .nullable()
      .when('type', {
        is: PurchaseActivityType.CouponCode,
        then: (schema) => schema.required(requiredError('Coupon')),
        otherwise: (schema) => schema.nullable()
      })
  }),
  purchase_activity_period: Yup.object().shape({
    start: Yup.date().required(requiredError('Start Date')),
    end: Yup.date()
      .when('has_endTime', {
        is: true,
        then: (schema) => schema.required('End date is required'),
        otherwise: (schema) => schema.nullable()
      })
      .test('is-greater', ErrorMessages.END_DATE_TIME_ERROR, function (val) {
        // eslint-disable-next-line no-invalid-this
        const { parent }: any = this;
        if (parent.has_endTime) {
          return val && parent.start && new Date(val) > new Date(parent.start);
        } else return true;
      }),
    has_endTime: Yup.boolean().default(false)
  }),
  purchase_requirement: PurchaseRequirementSchema.shape({
    products: Yup.array()
      .default([])
      .when(['type', 'productType'], {
        is: (type: string, productType: string) => type !== 'NONE' && productType === 'PURCHASE_PRODUCTS',
        then: (schema) => schema.min(1, ErrorMessages.MIN_PRODUCT_ERROR),
        otherwise: (schema) => schema
      }),
    collections: Yup.array()
      .default([])
      .when(['type', 'productType'], {
        is: (type: string, productType: string) => type !== 'NONE' && productType === 'PURCHASE_COLLECTIONS',
        then: (schema) => schema.min(1, ErrorMessages.MIN_COLLECTION_ERROR),
        otherwise: (schema) => schema
      })
  })
});
