import React from 'react';
import * as Unicons from '@iconscout/react-unicons';

type Props = {
  text: string | null;
};

export const FulfilledStatus: React.FC<Props> = ({ text }) => {
  switch (text) {
    case null:
      return (
        <div className="flex justify-start gap-0.5 items-center text-[#4f4700] bg-[#ffeb78] rounded-2xl py-1 pl-2 pr-4 font-medium">
          <Unicons.UilCircle className="h-4" /> Unfulfilled
        </div>
      );
    case 'partial':
      return (
        <div className="flex justify-start gap-0.5 items-center text-[#4f4700] bg-[#ffd6a4] rounded-2xl py-1 pl-2 pr-4 font-medium">
          <Unicons.UilAdjustHalf className="h-4" /> Partially fulfilled
        </div>
      );
    case 'fulfilled':
      return (
        <div className="flex gap-0.5 items-center text-[#666666] bg-[#e8e8e8] rounded-2xl py-1 pl-2 pr-4 font-medium">
          <div className="h-2 w-2 rounded-[50%] bg-[#616161] mx-1.5"></div> Fulfilled
        </div>
      );

    default:
      return <></>;
  }
};
