import { Formik } from 'formik';
import { cloneDeep } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { DiscountDialog } from '../discount-dialog';
import React, { FC, useMemo, useState } from 'react';
import { DiscountFields } from '../../../types/coupon-data-types';
import { DiscountPurchaseDrivenSetup } from './discount-purchase-driven-setup';
import { getCouponInitialValues } from '../../../utils/coupons/coupon-initial-values';
import { discountPurchaseDrivenDataHandler } from '../../../utils/coupons/coupon-data-handler';
import { CouponType, useCreateCouponMutation, useUpdateCouponMutation } from '../../../graphql';
import { DiscountUsageLimit } from '../../shared/discount/discount-usage-limit/discount-usage-limit';
import { DiscountUserBehaviour } from '../../shared/discount/discount-behaviour/discount-user-behaviour';
import { DiscountPurchaseDriven } from '../../../utils/validation-schemas/discount/discount-purchase-driven';
import { DiscountUserEligibility } from '../../shared/discount/discount-eligibility/discount-user-eligibility';
import { DiscountRequirement } from '../../shared/discount/discount-condition/discount-requirement';

type props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  couponDetails: any;
  setCouponDetails: (value: any) => void;
  refetch: () => void;
};

export const DiscountPurchaseDrivenDialog: FC<props> = ({
  open,
  setOpen,
  couponDetails,
  setCouponDetails,
  refetch
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const [createCouponMutation] = useCreateCouponMutation();
  const [updateCoupon] = useUpdateCouponMutation();

  const handleSave = async (values: any) => {
    const newValues = cloneDeep(values);
    const body = discountPurchaseDrivenDataHandler(newValues);

    try {
      if (couponDetails?.id) {
        await updateCoupon({
          variables: {
            data: body,
            couponId: couponDetails.id
          }
        });
      } else {
        await createCouponMutation({
          variables: {
            data: body
          }
        });
      }
      setOpen(false);
      setCouponDetails(null);
      enqueueSnackbar(`Hurray! Coupon ${couponDetails?.id ? 'updated' : 'created'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e) {
      setOpen(true);
      body.application.products = couponDetails?.application?.products || [];
      body.application.collections = couponDetails?.application?.collections || [];
      body.purchase_requirement.products = couponDetails?.purchase_requirement?.products || [];
      body.purchase_requirement.collections = couponDetails?.purchase_requirement?.collections || [];
      if (body.purchase_activity)
        body.purchase_activity.coupon_usage = couponDetails?.purchase_activity?.coupon_usage || null;
      setCouponDetails(body);

      enqueueSnackbar(`Oops! Coupon ${couponDetails ? 'update' : 'creation'} failed!`, {
        variant: 'error'
      });
    } finally {
      setActiveStep(0);
    }
  };

  const initialValues = useMemo(
    () => getCouponInitialValues(couponDetails, CouponType.PurchaseHistory),
    [couponDetails]
  );

  return (
    <Formik<DiscountFields>
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={true}
      validateOnMount={true}
      validateOnBlur={true}
      validationSchema={DiscountPurchaseDriven}
      onSubmit={handleSave}
    >
      {({ errors, submitForm, resetForm, isSubmitting }) => {
        return (
          <>
            <DiscountDialog
              open={open}
              setOpen={setOpen}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              type={CouponType.PurchaseHistory}
              errors={errors}
              discountDetails={couponDetails}
              setDiscountDetails={setCouponDetails}
              isSubmitting={isSubmitting}
              resetForm={resetForm}
              submitForm={submitForm}
              totalActiveStepIndex={4}
              content={
                <>
                  {activeStep === 0 && <DiscountPurchaseDrivenSetup />}
                  {activeStep === 1 && <DiscountUserBehaviour />}
                  {activeStep === 2 && <DiscountRequirement />}
                  {activeStep === 3 && <DiscountUserEligibility />}
                  {activeStep === 4 && <DiscountUsageLimit />}
                </>
              }
            />
          </>
        );
      }}
    </Formik>
  );
};
