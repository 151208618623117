import React from 'react';
import { GoogleAds } from './google-ads';
import { MetaPixels } from './meta-pixels';
import { enqueueSnackbar } from 'notistack';
import { Route, Routes } from 'react-router-dom';
import { GoogleAnalytics } from './google-analytics';
import { IntegrationsOptions } from './integrations-options';
import { getUpdateAnalyticsPayload } from '../../../../utils/integrations/index';
import { useAnalyticsQuery, useUpdateAnalyticsMutation } from '../../../../graphql';

export const IntegrationsMain: React.FC = () => {
  const { data, loading, refetch: statusRefetch } = useAnalyticsQuery();
  const [updateAnalytics] = useUpdateAnalyticsMutation();

  const handleAnalyticsStatusChange = async (value: boolean, analytics: string, refetch: () => void) => {
    if (!data) return;

    try {
      await updateAnalytics({
        variables: {
          configs: getUpdateAnalyticsPayload(data, value, analytics)
        }
      });

      statusRefetch();
      refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };

  return (
    <Routes>
      <Route path="" element={<IntegrationsOptions analyticsData={data} loading={loading} />} />
      <Route path="meta" element={<MetaPixels handleAnalyticsStatusChange={handleAnalyticsStatusChange} />} />
      <Route path="google-ads" element={<GoogleAds handleAnalyticsStatusChange={handleAnalyticsStatusChange} />} />
      <Route
        path="google-analytics"
        element={<GoogleAnalytics handleAnalyticsStatusChange={handleAnalyticsStatusChange} />}
      />
    </Routes>
  );
};
