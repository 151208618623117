import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { ColumnDef } from '@tanstack/table-core';
import {
  useShippingMethodsQuery,
  useUpdateShippingMethodMutation,
  useDeleteShippingMethodMutation,
  useUpdateShippingOrderMutation,
  ShippingType
} from '../../graphql';
import { ShippingDetailsNew } from '../../components/shipping/shipping-details-new';
import ConfirmationDialog from '../../components/shared/confirmation-dialog/confirmation-dialog';
import { Button, DataTableColumnHeader, Switch } from '../../components/flexyui';
import { enqueueSnackbar } from 'notistack';
import { Drawer, DrawerContent } from '../../components/flexyui/Drawer';
import { DataTableDnd } from '../../components/flexyui/DataTable/data-table-dnd';

export const ShippingV1 = () => {
  const [showShippingCard, setShowShippingCard] = useState(false);
  const [selectedShippingData, setSelectedShippingData] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [methodToDelete, setMethodToDelete] = useState('');
  const [rows, setRows] = useState<any>(null);
  const { data: shippingData, refetch, loading: loadingShippingData } = useShippingMethodsQuery();
  const [updateShippingMethodMutation] = useUpdateShippingMethodMutation();
  const [updateShippingOrder, { loading: updateShippingOrderLoading }] = useUpdateShippingOrderMutation();
  const [deleteShippingMethodMutation, { loading: deleting }] = useDeleteShippingMethodMutation();
  const toggleShippingCard = () => setShowShippingCard((prev) => !prev);

  useEffect(() => {
    const rowData = shippingData?.shippingMethods.map((row) => {
      return {
        id: row.id,
        name: row.name,
        etd: row.delivery_estimation,
        type: row.type,
        active: row.enabled,
        loading: false,
        row
      };
    });
    setRows(rowData);
  }, [shippingData]);

  const onRowClickHandler = (item: any) => {
    if (item?.row) {
      setSelectedShippingData(item.row);
      toggleShippingCard();
    }
  };

  const updateColumnData = (type: 'success' | 'error', rowId: string) => {
    const rowData = shippingData?.shippingMethods?.map((row) => {
      const loadingState = row.id === rowId ? true : false;
      return {
        id: row.id,
        name: row.name,
        etd: row.delivery_estimation,
        type: row.type,
        active: row.enabled,
        loading: type === 'success' ? loadingState : false,
        row
      };
    });
    setRows(rowData);
  };

  const handleActiveStatus = async (updatedData: any, rowId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, row, ...rest } = updatedData.original;
    const priceRangeRules = row.price_range_rules.map((rule: any) => {
      const newRule = { ...rule };
      if (rule.maximum_order_price === null) {
        delete newRule.maximum_order_price;
      }
      return newRule;
    });
    updateColumnData('success', rowId);
    try {
      await updateShippingMethodMutation({
        variables: {
          updateShippingMethodId: updatedData?.original.id,
          data: {
            type: updatedData.original.type,
            delivery_estimation: updatedData.original.etd,
            enabled: !updatedData.original.active,
            name: updatedData.original.name,
            price_range_rules: priceRangeRules
          }
        }
      });

      enqueueSnackbar('Hurray! Shipping activation updated successfully!', {
        variant: 'success'
      });
    } catch (error) {
      updateColumnData('error', rowId);
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
    await refetch();
  };

  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'name',
      enablePinning: true,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Group Name" />,
      cell: ({ row }) => {
        return <div className="py-2 px-3 flex items-center font-semibold">{row.original.name}</div>;
      }
    },
    {
      accessorKey: 'etd',
      enablePinning: true,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Default ETD Text" />,
      cell: ({ row }) => {
        return <div className="py-2 px-3">{row.original.etd}</div>;
      }
    },
    {
      accessorKey: 'type',
      enablePinning: true,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Type" className="flex justify-center" />,
      cell: ({ row }) => {
        return (
          <div className="flex py-2 px-3 justify-center items-center">
            {row?.original.type === ShippingType.Price ? 'Price Based' : 'Weight Based'}
          </div>
        );
      }
    },
    {
      accessorKey: 'active',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Active" className="flex justify-center" />,
      id: 'Active',
      cell: ({ row }) => {
        return (
          <div className="flex py-2 px-3 justify-center items-center">
            <Switch
              checked={row?.original.loading ? !row?.original.active : row?.original.active}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
              onCheckedChange={() => handleActiveStatus(row, row.original.id)}
            />
          </div>
        );
      }
    },
    {
      accessorKey: 'Action',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Action" className="flex justify-center" />,
      id: 'Action',
      cell: ({ row }) => {
        return (
          <div className="flex justify-center py-1.5 px-3">
            <Button
              size="icon"
              variant="icon"
              disabled={false}
              onClick={(e) => {
                e?.stopPropagation();
                setShowModal(true);
                setMethodToDelete(row?.original.id || '');
              }}
              className="text-[#595F74] hover:text-red-500"
            >
              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
            </Button>
          </div>
        );
      }
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDeleteShippingMethod = async () => {
    // e?.stopPropagation();
    await deleteShippingMethodMutation({
      variables: {
        deleteShippingMethodId: methodToDelete
      }
    });

    refetch();
    // setShowModal(false);
  };

  const handleConfirm = () => {
    handleDeleteShippingMethod();
    setShowModal(false);
  };

  const handleDndSaveChanges = async (items: any) => {
    await updateShippingOrder({
      variables: {
        data: items.map((item: any, index: any) => {
          return {
            position: index,
            shipping_id: item?.original?.id
          };
        })
      }
    });
    await refetch();
  };

  return (
    <div className="relative">
      <div className="absolute -top-[3.25rem] right-0">
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            setSelectedShippingData(null);
            toggleShippingCard();
          }}
        >
          <Unicons.UilPlus size={18} className="mr-2" />
          Add Shipping
        </Button>
      </div>

      <div>
        <DataTableDnd
          data={rows}
          loadingRows={3}
          columns={columns}
          showFilterInput={false}
          onRowClick={onRowClickHandler}
          updateMutationLoading={updateShippingOrderLoading}
          handleDndSaveChanges={handleDndSaveChanges}
        />
      </div>

      <Drawer direction="right" open={showShippingCard} onOpenChange={(value: boolean) => setShowShippingCard(value)}>
        <DrawerContent>
          <ShippingDetailsNew
            shippingData={selectedShippingData}
            handleShowShippingDetail={toggleShippingCard}
            refetch={refetch}
          />
        </DrawerContent>
      </Drawer>

      <ConfirmationDialog
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={handleConfirm}
        headerText="Delete Shipping?"
        text="This Action will delete the selected shipping"
        confirmButtonText="Delete"
        confirmActionVariant="destructive"
        loading={deleting}
      />
    </div>
  );
};
