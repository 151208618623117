import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label
} from '../../../../flexyui';
import { Formik } from 'formik';
import {
  GoogleAnalyticsConversionAction,
  useAddGoogleAnalyticsMutation,
  useUpdateGoogleAnalyticsMutation
} from '../../../../../graphql';
import React, { useMemo } from 'react';
import { enqueueSnackbar } from 'notistack';
import { ReactComponent as LoadingIcon } from '../../../../../assets/images/loading.svg';
import { GoogleAnalyticsSchema } from '../../../../../utils/validation-schemas/integrations';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  conversionData: GoogleAnalyticsConversionAction | null;
  setConversionData: (value: GoogleAnalyticsConversionAction | null) => void;
  handleGoogleAnalyticsConversionStatus: () => void;
  refetch: () => void;
};

export const GoogleAnalyticsDialog: React.FC<Props> = ({
  open,
  setOpen,
  conversionData,
  setConversionData,
  handleGoogleAnalyticsConversionStatus,
  refetch
}) => {
  const [addConversion] = useAddGoogleAnalyticsMutation();
  const [updateConversion] = useUpdateGoogleAnalyticsMutation();

  const handleSave = async (values: any) => {
    try {
      if (conversionData) {
        await updateConversion({
          variables: {
            configs: values,
            updateGoogleAnalyticsId: conversionData.id
          }
        });
      } else {
        handleGoogleAnalyticsConversionStatus();
        await addConversion({
          variables: {
            configs: values
          }
        });
      }
      enqueueSnackbar(`Conversion has been ${conversionData ? 'updated' : 'added'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setOpen(false);
      setConversionData(null);
    }
  };

  const initialValues = useMemo(() => {
    return {
      enabled: conversionData?.enabled ?? true,
      measurement_id: conversionData?.measurement_id || '',
      api_secret: conversionData?.api_secret || ''
    };
  }, [conversionData]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={true}
      validateOnMount={true}
      validateOnBlur={true}
      validationSchema={GoogleAnalyticsSchema}
      onSubmit={handleSave}
    >
      {({ values, errors, touched, getFieldProps, submitForm, resetForm, isSubmitting }) => {
        return (
          <Dialog open={open} onOpenChange={(value) => setOpen(value)}>
            <DialogContent className="!gap-0" size="sm" close={true}>
              <div>
                <DialogHeader>
                  <DialogTitle>
                    <p>{conversionData ? 'Edit' : 'Add'} Conversion Data</p>
                  </DialogTitle>
                  <DialogDescription className="!p-0 !m-0">
                    <div className="p-4">
                      <div>
                        <Label size="paragraph">Measurement ID</Label>
                        <Input
                          {...getFieldProps('measurement_id')}
                          error={touched.measurement_id && !!errors.measurement_id}
                          errorMessage={errors.measurement_id}
                          className="text-[#121b38] mt-1"
                        />
                      </div>
                      <div className="mt-3">
                        <Label size="paragraph">API Secret</Label>
                        <Input
                          {...getFieldProps('api_secret')}
                          error={touched.api_secret && !!errors.api_secret}
                          errorMessage={errors.api_secret}
                          className="text-[#121b38]"
                        />
                      </div>
                    </div>
                  </DialogDescription>
                </DialogHeader>
              </div>
              <DialogFooter>
                <Button
                  variant={'outline'}
                  size={'md'}
                  onClick={() => {
                    setConversionData(null);
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant={'default'}
                  size={'md'}
                  type="submit"
                  disabled={Object.keys(errors).length > 0 || isSubmitting}
                  onClick={async () => {
                    await submitForm();
                    resetForm();
                  }}
                >
                  {isSubmitting ? (
                    <LoadingIcon height={20} className={'animate-spin text-white'} />
                  ) : conversionData ? (
                    'Save'
                  ) : (
                    'Add'
                  )}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        );
      }}
    </Formik>
  );
};
